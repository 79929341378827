import React from 'react';
import { VideoTypes } from '../../../enums';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  type: VideoTypes;
}

const VideoTypeHeader: React.FC<Props> = ({
  type,
}) => {
  const {t} = useTranslation();

  const instructions: string[] = t(`video.videoTypes.${type}.instructions`, {returnObjects: true});

  return (
      <Box>
        <Typography variant="h4" sx={{mb: 2}}>
          {t(`video.videoTypes.${type}.title`)}
        </Typography>
        <ul>
          {instructions.map((instruction) => (
            <li key={instruction}>
              <Typography variant='body1'>
                {instruction}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
  );
}

export default VideoTypeHeader;

