import {Typography} from '@mui/material';
import React, {ReactNode} from 'react';
import {SxProps, Theme, styled} from '@mui/material/styles';

interface Props {
  children?: ReactNode;
  sx?: SxProps<Theme>;
}

const StyledTypography = styled(Typography)(() => ({
  marginTop: 20,
  marginBottom: 20,
}));

const PageSubtitle: React.FC<Props> = ({children, sx}) => {
  return (
    <StyledTypography sx={{...sx}} variant="h2">
      {children}
    </StyledTypography>
  );
};

export default PageSubtitle;
