import React, {useEffect, useRef} from 'react';
import {Box} from '@mui/material';
import PlayPause from './Controls/PlayPause';
import Skip from './Controls/Skip';
import SlowMotion from './Controls/SlowMotion';
import Seekbar from './Controls/Seekbar';
import Download from './Controls/Download';
import Fullscreen from './Controls/Fullscreen';
import PlayerSessionShiftSlider from '../../PlayerSessions/PlayerSessionShiftSlider';
import Volume from './Controls/Volume';
import {PlayerSessionShift} from '../../../interfaces';

interface PlayerControls {
  paused: boolean;
  rate: number;
  isFullscreen: boolean;
  seeking: boolean;
  slowMotionPreviousPausedState: boolean;
  previousMuteState: boolean;
  previousVolume: number;
  reversePlay: boolean;
}

interface Props {
  videoRef: React.RefObject<HTMLVideoElement>;
  sourceUrl?: string;
  setVideoIndex: React.Dispatch<React.SetStateAction<number>>;
  shiftClips: PlayerSessionShift[];
  videoIndex: number;
}

const VideoPlayer: React.FC<Props> = ({
  videoRef,
  sourceUrl,
  setVideoIndex,
  shiftClips,
  videoIndex,
}) => {
  const [playerControls, setPlayerControls] = React.useState<PlayerControls>({
    paused: true,
    rate: 0,
    isFullscreen: false,
    seeking: false,
    slowMotionPreviousPausedState: false,
    previousMuteState: false,
    previousVolume: 1,
    reversePlay: false,
  });
  const [reverseInterval, setReverseInterval] =
    React.useState<NodeJS.Timeout | null>(null);
  const [showShifts, setShowShifts] = React.useState(false);
  const videoContainerRef = useRef<HTMLDivElement>(null);

  const clearReverseInterval = () => {
    if (reverseInterval) {
      clearInterval(reverseInterval);
      setReverseInterval(null);
    }
  };

  const togglePlay = () => {
    if (!videoRef.current) {
      return;
    }
    clearReverseInterval();
    if (playerControls.paused) {
      videoRef.current?.play();
      videoRef.current.playbackRate = 1;
      videoRef.current.muted = playerControls.previousMuteState;
      setPlayerControls({
        ...playerControls,
        paused: false,
        rate: 1,
        slowMotionPreviousPausedState: false,
        reversePlay: false,
      });
    } else {
      videoRef.current?.pause();
      setPlayerControls({
        ...playerControls,
        paused: true,
        reversePlay: false,
      });
    }
  };

  const skipFiveSeconds = (backward: boolean) => {
    if (videoRef.current) {
      videoRef.current.currentTime += backward ? -5 : 5;
    }
  };

  const slowMotionForward = () => {
    clearReverseInterval();
    if (videoRef.current) {
      if (
        playerControls.rate === 1 ||
        playerControls.reversePlay ||
        playerControls.paused
      ) {
        setPlayerControls({
          ...playerControls,
          slowMotionPreviousPausedState: playerControls.paused,
          previousMuteState: videoRef.current.muted,
          rate: 0.4,
          paused: false,
          reversePlay: false,
        });
        videoRef.current?.play();
        videoRef.current.muted = true;
        videoRef.current.playbackRate = 0.4;
      } else if (playerControls.slowMotionPreviousPausedState) {
        setPlayerControls({
          ...playerControls,
          paused: playerControls.slowMotionPreviousPausedState,
          reversePlay: false,
        });
        videoRef.current.muted = playerControls.previousMuteState;
        videoRef.current.playbackRate = 1;
        videoRef.current.pause();
      } else {
        videoRef.current.muted = playerControls.previousMuteState;
        videoRef.current.playbackRate = 1;
        videoRef.current.play();
        setPlayerControls({
          ...playerControls,
          rate: 1,
          reversePlay: false,
          paused: false,
          slowMotionPreviousPausedState: false,
        });
      }
    }
  };

  const slowMotionBackward = () => {
    if (videoRef.current) {
      if (!playerControls.reversePlay) {
        setPlayerControls({
          ...playerControls,
          slowMotionPreviousPausedState: playerControls.paused,
          previousMuteState: videoRef.current.muted,
          reversePlay: true,
          paused: false,
        });
        videoRef.current.muted = true;
        videoRef.current.playbackRate = 0;
        videoRef.current.play();
        if (!reverseInterval) {
          const newReverseInterval = setInterval(() => {
            if (videoRef.current) {
              videoRef.current.currentTime -= 0.1;
            } else {
              clearInterval(newReverseInterval);
            }
          }, 500);
          setReverseInterval(newReverseInterval);
        }
      } else if (playerControls.slowMotionPreviousPausedState) {
        setPlayerControls({
          ...playerControls,
          paused: playerControls.slowMotionPreviousPausedState,
          reversePlay: false,
        });
        clearReverseInterval();
        videoRef.current.muted = playerControls.previousMuteState;
        videoRef.current.playbackRate = 1;
        videoRef.current.pause();
      } else {
        clearReverseInterval();
        videoRef.current.muted = playerControls.previousMuteState;
        videoRef.current.playbackRate = 1;
        setPlayerControls({
          ...playerControls,
          rate: 1,
          reversePlay: false,
        });
      }
    }
  };

  const toggleFullscreen = async () => {
    const videoElement = videoContainerRef.current;
    if (videoElement) {
      console.log(playerControls.isFullscreen);
      if (!playerControls.isFullscreen) {
        await videoElement.requestFullscreen();
        setPlayerControls({...playerControls, isFullscreen: true});
      } else {
        await document.exitFullscreen();
        setPlayerControls({...playerControls, isFullscreen: false});
      }
    }
  };

  const loadNewShift = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
      clearReverseInterval();
      setPlayerControls({...playerControls, paused: true, reversePlay: false});
    }
  };

  const handleMouseEnter = () => {
    setShowShifts(true);
  };

  const hideShiftRow = () => {
    if (showShifts) {
      setShowShifts(false);
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setPlayerControls({...playerControls, isFullscreen: false});
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [playerControls]);

  if (!sourceUrl) {
    return null;
  }

  return (
    <Box
      sx={{display: 'flex', flexDirection: 'column'}}
      ref={videoContainerRef}>
      {playerControls.isFullscreen && <Box sx={{flexGrow: 1, minHeight: 20}} />}
      <Box
        onMouseEnter={hideShiftRow}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: playerControls.isFullscreen ? '90vh' : 'auto',
          justifyContent: 'center',
          alignSelf: 'center',
        }}>
        <video
          ref={videoRef}
          width="100%"
          disablePictureInPicture
          onLoadedMetadata={loadNewShift}
          controls={false}>
          <source src={sourceUrl} type="video/mp4" />
        </video>
      </Box>
      <Box sx={{display: {xs: 'flex', md: 'none'}}}>
        <Seekbar videoRef={videoRef} playerControls={playerControls} />
        <Download href={sourceUrl} />
        <Fullscreen
          isFullscreen={playerControls.isFullscreen}
          toggleFullscreen={toggleFullscreen}
        />
      </Box>
      <Box
        onMouseEnter={hideShiftRow}
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          marginBottom: playerControls.isFullscreen ? 1 : 4,
        }}>
        <Box>
          <Skip backward onClick={() => skipFiveSeconds(true)} />
          <SlowMotion
            backward
            onClick={slowMotionBackward}
            playbackRate={videoRef.current?.playbackRate || 0}
            paused={playerControls.paused}
            reversing={playerControls.reversePlay}
          />
          <PlayPause paused={playerControls.paused} togglePlay={togglePlay} />
          <SlowMotion
            onClick={slowMotionForward}
            playbackRate={videoRef.current?.playbackRate || 0}
            paused={playerControls.paused}
          />
          <Skip onClick={() => skipFiveSeconds(false)} />
        </Box>
        <Box sx={{display: {xs: 'none', md: 'flex'}, flexGrow: 1}}>
          <Seekbar videoRef={videoRef} playerControls={playerControls} />
        </Box>
        <Box sx={{display: {xs: 'none', md: 'flex'}}}>
          <Download href={sourceUrl} />
          <Fullscreen
            isFullscreen={playerControls.isFullscreen}
            toggleFullscreen={toggleFullscreen}
          />
        </Box>
        <Box sx={{maxWidth: 200, flexGrow: 1}}>
          <Volume
            videoRef={videoRef}
            setPreviousVolume={v =>
              setPlayerControls({...playerControls, previousVolume: v})
            }
            previousVolume={playerControls.previousVolume}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: playerControls.isFullscreen ? 'initial' : 'none',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        }}>
        <Box
          sx={{
            flex: 1,
            overflow: 'auto',
            maxHeight: showShifts ? 'auto' : 0,
            opacity: showShifts ? 1 : 0,
            transition: 'max-height 0.5s ease-in-out, opacity 0.5s ease-in-out',
          }}>
          {shiftClips?.length > 0 && (
            <PlayerSessionShiftSlider
              shiftClips={shiftClips}
              videoIndex={videoIndex}
              setVideoIndex={setVideoIndex}
              videoRef={videoRef}
              solidButtons={true}
            />
          )}
        </Box>
      </Box>
      {playerControls.isFullscreen && (
        <Box
          onMouseEnter={handleMouseEnter}
          sx={{flexGrow: 1, minHeight: 20}}
        />
      )}
    </Box>
  );
};

export default VideoPlayer;
