import React from 'react';
import { Alert, Link } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { NavLink } from 'react-router-dom';

import {CurrentMembershipLevels} from '../../enums';

interface Props {
  minMembershipLevel: CurrentMembershipLevels;
}

const MembershipLocked: React.FC<Props> = ({minMembershipLevel}) => {
  let message = '';

  if (minMembershipLevel === CurrentMembershipLevels.elite) {
    message = 'This feature is only available in the ELITE and PRO memberships.';
  } else if (minMembershipLevel === CurrentMembershipLevels.pro) {
    message = 'This feature is only available in the PRO membership.';
  }

  if (message.length === 0) {
    return null;
  }

  return (
    <Alert
      severity="error"
      variant="outlined"
      icon={<LockIcon />}
    >
      {message} <Link
        color={'#fff'}
        component={NavLink}
        to="/membership">Upgrade Membership For Future Sessions
      </Link>
    </Alert>
  );
};

export default MembershipLocked;
