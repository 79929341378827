import { Button, TableCell, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import SessionInfo from "./SessionInfo";
import SessionTileStat from "./SessionTileStat";

interface Props {
  type: string;
  startTime: Date;
  endTime: Date;
  participation: number;
  teamSize?: number;
  detailLink: string;
}

const TeamSessionListItem: React.FC<Props> = ({
  type,
  startTime,
  endTime,
  participation,
  teamSize,
  detailLink,
}) => {
  const {t} = useTranslation();

  return (
    <TableRow>
      <TableCell>
        <SessionInfo
          type={type}
          startTime={startTime}
          endTime={endTime}
        />
      </TableCell>
      <TableCell>
        <SessionTileStat
          label="Participation"
          value={`${participation}${teamSize ? `/${teamSize}` : ''}`}
        />
      </TableCell>
      <TableCell align="right">
        <Button component={NavLink} to={detailLink}>
          {t('general.details')}
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default TeamSessionListItem;
