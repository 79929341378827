import {Box, Typography} from '@mui/material';
import React, {useMemo} from 'react';
import {VictoryArea, VictoryChart, VictoryPolarAxis} from 'victory';
import {useTranslation} from 'react-i18next';
import {SKY_BLUE, VIVID_VIOLET} from '../../../styles';
import {PositionGroup} from '../../../enums';

interface Props {
  positionGroup: PositionGroup;
  practiceEfficiencyMax: number;
  data: {x: string; y: number}[];
}

const EfficiencyPolarChart: React.FC<Props> = ({
  data,
  practiceEfficiencyMax,
  positionGroup,
}) => {
  const {t} = useTranslation();
  const isForwards = useMemo(
    () => positionGroup === PositionGroup.forwards,
    [positionGroup],
  );
  return (
    <Box sx={{flexGrow: 1}}>
      <Box
        sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Box
          style={{
            height: 25,
            width: 25,
            border: isForwards
              ? `2px solid ${SKY_BLUE}`
              : `2px solid ${VIVID_VIOLET}`,
            backgroundColor: isForwards ? `${SKY_BLUE}33` : `${VIVID_VIOLET}33`,
            marginRight: 5,
          }}
        />
        <Typography variant="h4">
          {isForwards ? t('position.forwards') : t('position.defense')}
        </Typography>
      </Box>
      <VictoryChart
        domain={{y: [0, practiceEfficiencyMax]}}
        padding={{left: 20, right: 20, top: 20, bottom: 30}}
        height={300}
        polar>
        <VictoryPolarAxis
          tickFormat={t => `${t}`}
          style={{
            axisLabel: {padding: 10},
            ticks: {stroke: '#fff', size: 5},
            tickLabels: {fontSize: 12, fill: '#fff', angle: 0},
            grid: {stroke: '#fff', strokeWidth: 0.25, opacity: 0.5},
          }}
        />
        <VictoryPolarAxis
          tickFormat={t => `${t}%`}
          axisAngle={90}
          dependentAxis
          style={{
            axisLabel: {padding: 30},
            tickLabels: {
              fontSize: 10,
              fill: '#fff',
              angle: 0,
              textAnchor: 'end',
            },
            grid: {stroke: '#fff', strokeWidth: 0.25, opacity: 0.5},
          }}
        />
        <VictoryArea
          data={data}
          style={{
            data: {
              fill: isForwards ? SKY_BLUE : VIVID_VIOLET,
              stroke: isForwards ? SKY_BLUE : VIVID_VIOLET,
              fillOpacity: 0.2,
            },
          }}
        />
      </VictoryChart>
    </Box>
  );
};

export default EfficiencyPolarChart;
