import { Box, Button } from '@mui/material';
import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface Props {
  children: ReactNode;
  navigationUrl: string;
}

const StyledButtonWrapper = styled(Box)(() => ({
  marginBottom: 20,
}));

const BackButton: React.FC<Props> = ({
  children,
  navigationUrl,
}) => {
  return (
    <StyledButtonWrapper>
      <Button
        component={NavLink}
        to={navigationUrl}
        startIcon={<ChevronLeftIcon />}
      >
        {children}
      </Button>
    </StyledButtonWrapper>
  );
};

export default BackButton;
