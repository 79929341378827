import { useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // track pageview with gtag / react-ga / react-ga4, for example:
    if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
      // delay reporting by 250ms to let react-helmet update title
      setTimeout(() => {
        ReactGA.send('pageview');
      }, 250);
    }
  }, [location]);
};

export default usePageTracking;
