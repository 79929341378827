import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  SxProps,
  Theme,
} from '@mui/material';
import React, {useMemo, useState} from 'react';
import styles from '../../styles';
import {useTranslation} from 'react-i18next';
import {
  getPlayerSessionExport,
  getTeamSessionExport,
} from '../../api/exportAPI';
import {TeamSessionExportTypes} from '../../enums';

interface Props {
  playerSessionId?: number;
  playerSessionStartTime?: string;
  teamSessionId?: number;
  teamSessionStartTime?: string;
  teamSessionHasPuckDropEvents?: boolean;
  teamHasHudlConfig?: boolean;
  teamHasSpiideoConfig?: boolean;
  teamHasCatapultConfig?: boolean;
  buttonSx?: SxProps<Theme>;
}

const ExportSessionModal: React.FC<Props> = ({
  playerSessionId,
  playerSessionStartTime,
  teamSessionId,
  teamSessionStartTime,
  teamSessionHasPuckDropEvents,
  teamHasHudlConfig,
  teamHasSpiideoConfig,
  teamHasCatapultConfig,
  buttonSx,
}) => {
  const {t} = useTranslation();
  const [showExportSessionModal, setShowExportSessionModal] =
    useState<boolean>(false);
  const [downloadType, setDownloadType] = useState<
    | TeamSessionExportTypes.csvPlayers
    | TeamSessionExportTypes.csvShifts
    | TeamSessionExportTypes.hudlSportscode
    | TeamSessionExportTypes.spiideoPerform
    | TeamSessionExportTypes.catapultThunder
  >(TeamSessionExportTypes.csvShifts);

  const downloadReport = async () => {
    if (
      playerSessionId &&
      playerSessionStartTime &&
      [
        TeamSessionExportTypes.csvPlayers,
        TeamSessionExportTypes.csvShifts,
      ].indexOf(downloadType) !== -1
    ) {
      try {
        await getPlayerSessionExport({
          playerSessionId,
          playerSessionStartTime,
          format: downloadType as
            | TeamSessionExportTypes.csvPlayers
            | TeamSessionExportTypes.csvShifts,
        });
      } catch (err) {
        console.error(err);
      }
      setShowExportSessionModal(false);
    } else if (
      teamSessionId &&
      teamSessionStartTime &&
      [
        TeamSessionExportTypes.csvPlayers,
        TeamSessionExportTypes.csvShifts,
        TeamSessionExportTypes.hudlSportscode,
        TeamSessionExportTypes.spiideoPerform,
        TeamSessionExportTypes.catapultThunder,
      ].indexOf(downloadType) !== -1
    ) {
      try {
        await getTeamSessionExport({
          teamSessionId,
          teamSessionStartTime,
          format: downloadType as
            | TeamSessionExportTypes.csvPlayers
            | TeamSessionExportTypes.csvShifts
            | TeamSessionExportTypes.hudlSportscode
            | TeamSessionExportTypes.spiideoPerform
            | TeamSessionExportTypes.catapultThunder,
        });
      } catch (err) {
        console.error(err);
      }
      setShowExportSessionModal(false);
    }
  };

  const showNoPuckDropEventsError = useMemo(() => {
    return (
      !teamSessionHasPuckDropEvents &&
      [
        TeamSessionExportTypes.hudlSportscode,
        TeamSessionExportTypes.spiideoPerform,
        TeamSessionExportTypes.catapultThunder,
      ].includes(downloadType)
    );
  }, [downloadType, teamSessionHasPuckDropEvents]);

  const showNoHudlConfigError = useMemo(() => {
    return (
      !teamHasHudlConfig &&
      downloadType === TeamSessionExportTypes.hudlSportscode
    );
  }, [downloadType, teamHasHudlConfig]);

  const showNoSpiideoConfigError = useMemo(() => {
    return (
      !teamHasSpiideoConfig &&
      downloadType === TeamSessionExportTypes.spiideoPerform
    );
  }, [downloadType, teamHasSpiideoConfig]);

  const showNoCatapultConfigError = useMemo(() => {
    return (
      !teamHasCatapultConfig &&
      downloadType === TeamSessionExportTypes.catapultThunder
    );
  }, [downloadType, teamHasCatapultConfig]);

  return (
    <>
      <Button
        variant="contained"
        sx={{mr: 2, ...buttonSx}}
        onClick={() => setShowExportSessionModal(true)}>
        {t('general.export')}
      </Button>
      <Modal
        open={showExportSessionModal}
        onClose={() => {
          setShowExportSessionModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={styles.modal}>
          <FormControl>
            <FormLabel id="export-radio-buttons-group-label">
              {t('general.selectExportType')}
            </FormLabel>
            <RadioGroup
              onChange={v =>
                setDownloadType(
                  v.target.value as
                    | TeamSessionExportTypes.csvPlayers
                    | TeamSessionExportTypes.csvShifts
                    | TeamSessionExportTypes.hudlSportscode
                    | TeamSessionExportTypes.spiideoPerform
                    | TeamSessionExportTypes.catapultThunder,
                )
              }
              aria-labelledby="export-radio-buttons-group-label"
              value={downloadType}
              name="radio-buttons-group">
              <FormControlLabel
                value={TeamSessionExportTypes.csvPlayers}
                control={<Radio />}
                label={t('export.playersCsv')}
              />
              <FormControlLabel
                value={TeamSessionExportTypes.csvShifts}
                control={<Radio />}
                label={t('export.shiftsCsv')}
              />
              {teamSessionId && (
                <>
                  <FormControlLabel
                    value={TeamSessionExportTypes.hudlSportscode}
                    control={<Radio />}
                    label={t('export.hudlSportscode')}
                  />
                  <FormControlLabel
                    value={TeamSessionExportTypes.spiideoPerform}
                    control={<Radio />}
                    label={t('export.spiideoPerform')}
                  />
                  <FormControlLabel
                    value={TeamSessionExportTypes.catapultThunder}
                    control={<Radio />}
                    label={t('export.catapultThunder')}
                  />
                </>
              )}
            </RadioGroup>
          </FormControl>
          {/* errors */}
          {showNoPuckDropEventsError && (
            <Alert severity="error" variant="outlined" sx={{mb: 1}}>
              {t('export.errorMissingTeamSessionSessionEvents')}
            </Alert>
          )}
          {showNoHudlConfigError && (
            <Alert severity="error" variant="outlined" sx={{mb: 1}}>
              {t('export.errorMissingTeamHudlConfig')}
            </Alert>
          )}
          {showNoSpiideoConfigError && (
            <Alert severity="error" variant="outlined" sx={{mb: 1}}>
              {t('export.errorMissingTeamSpiideoConfig')}
            </Alert>
          )}
          {showNoCatapultConfigError && (
            <Alert severity="error" variant="outlined" sx={{mb: 1}}>
              {t('export.errorMissingTeamCatapultConfig')}
            </Alert>
          )}
          <Box sx={{mt: 2}}>
            <Button
              disabled={
                showNoPuckDropEventsError ||
                showNoHudlConfigError ||
                showNoSpiideoConfigError ||
                showNoCatapultConfigError
              }
              onClick={downloadReport}>
              {t('general.export')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ExportSessionModal;
