import {Container, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Roles} from '../enums';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {getPlayerSessionsAsync} from '../store/playerSessionSlice';
import FamilyMemberList from './shared/FamilyMemberList';
import PageTitle from './shared/PageTitle';
import PlayerSessionsList from './shared/PlayerSessionsList';
import TeamList from './shared/TeamList';
import {Navigate} from 'react-router-dom';

interface Props {}

const Dashboard: React.FC<Props> = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const currentState = useAppSelector(state => ({
    profile: state.profile,
    playerSession: state.playerSession,
    team: state.team,
    family: state.family,
  }));

  useEffect(() => {
    dispatch(getPlayerSessionsAsync({}));
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {t('navigation.pageTitle', {pageName: t('pages.dashboard')})}
        </title>
      </Helmet>
      <Container>
        {(currentState?.profile?.profile?.role === Roles.player ||
          currentState?.profile?.profile?.role === Roles.pro) && (
          <>
            <PageTitle>Recent Player Sessions</PageTitle>
            <PlayerSessionsList
              navigateUrlPrefix="/playersessions"
              playerSessions={[
                ...(currentState.playerSession?.playerSessions ?? []),
              ]}
              showDate={true}
            />
          </>
        )}
        {currentState?.profile?.profile?.role === Roles.coach && (
          <>
            {(currentState?.team?.teams ?? []).length > 0 ? (
              <Navigate
                to={`/teams/${currentState?.team?.teams[0].id}/players`}
              />
            ) : (
              <>
                <PageTitle>Teams</PageTitle>
                <TeamList teams={[...(currentState.team?.teams ?? [])]} />
              </>
            )}
          </>
        )}
        {currentState?.profile?.profile?.role === Roles.parent && (
          <>
            <PageTitle>Family Members</PageTitle>
            {(currentState?.family?.families ?? []).length > 0 &&
            currentState?.family?.families[0].childProfiles ? (
              <FamilyMemberList
                familyMembers={[
                  ...(currentState?.family?.families?.[0]?.childProfiles ?? []),
                ]}
                showPlayerSessionsLink={true}
                showMembershipLink={true}
              />
            ) : (
              <Typography variant="body1">No Family Configured</Typography>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default Dashboard;
