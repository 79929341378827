import { AxiosPromise } from 'axios';
import apiGraphQL from "./apiGraphQL";

export function updatePlayerSessionShift({
  id,
  valid,
}: {
  id: number;
  valid: boolean;
}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      apiGraphQL
        .post('/graphql', {
          query: `mutation ($id: Int!, $valid: Boolean) {
            updatePlayerSessionShift(id: $id, valid: $valid) {
              id,
            }
          }`,
          variables: {
            id,
            valid,
          },
        })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}
