import {Alert, Box, Button, TextField} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {pixellotMatchIdentifierRegex} from '../../../services/videoService';
import VideoTypeHeader from './VideoTypeHeader';
import {VideoTypes} from '../../../enums';
import AddVideoWrapper from './AddVideoWrapper';
import VideoSessionEventAnchor from './VideoSessionEventAnchor';
import {getPixellotVideoDetails} from '../../../api/sessionVideoAPI';
import {SessionEvent} from '../../../interfaces';

interface Props {
  setShowModal: (showModal: boolean) => void;
  createSegment: (params: {
    fileName?: string;
    parts?: number;
    veoUrl?: string;
    pixellotUrl?: string;
    userSelectedStartTime?: Date;
  }) => object;
  processVideo: () => void;
  refresh: () => void;
  sessionEvents: SessionEvent[];
}

const AddPixellotVideo: React.FC<Props> = ({
  setShowModal,
  createSegment,
  processVideo,
  refresh,
  sessionEvents,
}) => {
  const {t} = useTranslation();

  const [pixellotUrl, setPixellotUrl] = useState<string>('');
  const [isValidPixellotUrl, setIsValidPixellotUrl] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [videoStartTime, setVideoStartTime] = useState<Date | null>(null);

  useEffect(() => {
    setIsValidPixellotUrl(!!pixellotMatchIdentifierRegex.exec(pixellotUrl));
  }, [pixellotUrl]);

  return (
    <AddVideoWrapper type={VideoTypes.pixellot}>
      <VideoTypeHeader type={VideoTypes.pixellot} />
      {pixellotUrl.length > 0 && !isValidPixellotUrl && (
        <Alert severity="error" variant="outlined" sx={{mb: 1}}>
          {t('video.videoTypes.pixellot.invalidUrlError')}
        </Alert>
      )}
      {sessionEvents.length > 0 ? (
        <>
          {(previewUrl ?? '').length === 0 && (
            <>
              <TextField
                label="Pixellot URL"
                sx={{width: '100%', mt: 3, mb: 3}}
                onChange={e => setPixellotUrl(e.target.value)}
                value={pixellotUrl}
              />
              <Box sx={{textAlign: 'right'}}>
                <Button
                  variant="contained"
                  disabled={!isValidPixellotUrl}
                  onClick={async () => {
                    const res = await getPixellotVideoDetails({
                      url: pixellotUrl,
                    });
                    setPreviewUrl(
                      res.data?.data?.getPixellotVideoDetails?.urlStream,
                    );
                  }}>
                  {t('general.continue')}
                </Button>
              </Box>
            </>
          )}
          {(previewUrl ?? '').length > 0 && (
            <>
              <VideoSessionEventAnchor
                videoUrl={previewUrl}
                sessionEvents={sessionEvents ?? []}
                setVideoStartTime={setVideoStartTime}
              />
              <Box sx={{textAlign: 'right'}}>
                <Button
                  variant="contained"
                  disabled={!isValidPixellotUrl || !videoStartTime}
                  onClick={async () => {
                    await createSegment({
                      pixellotUrl,
                      userSelectedStartTime: videoStartTime!,
                    });
                    await processVideo();
                    await refresh();
                    setShowModal(false);
                  }}>
                  {t('general.submit')}
                </Button>
              </Box>
            </>
          )}
        </>
      ) : (
        <Alert severity="info" variant="outlined" sx={{mb: 1}}>
          {t('video.videoTypes.pixellot.noSessionEventsError')}
        </Alert>
      )}
    </AddVideoWrapper>
  );
};

export default AddPixellotVideo;
