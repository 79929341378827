import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import React, {useState} from 'react';
import {getTimeZoneString} from '../../services/dateService';
import styles from '../../styles';
import {useTranslation} from 'react-i18next';

const basicTimezones = [
  {
    abbr: 'AT',
    regex: /A[S|D]?T/,
  },
  {
    abbr: 'ET',
    regex: /E[S|D]?T/,
  },
  {
    abbr: 'CT',
    regex: /C[S|D]?T/,
  },
  {
    abbr: 'MT',
    regex: /M[S|D]?T/,
  },
  {
    abbr: 'PT',
    regex: /P[S|D]?T/,
  },
  {
    abbr: 'AKT',
    regex: /AK[S|D]?T/,
  },
  {
    abbr: 'HT',
    regex: /H[S|D]?T/,
  },
];

const advanceTimezones = [
  'AST',
  'ADT',
  'EST',
  'EDT',
  'CST',
  'CDT',
  'MST',
  'MDT',
  'PST',
  'PDT',
  'AKST',
  'AKDT',
  'HST',
  'HDT',
];

interface Props {
  timeZone?: string;
  onTimeZoneChange: (timeZone: string) => void;
}

const TimeZoneButton: React.FC<Props> = ({timeZone, onTimeZoneChange}) => {
  const {t} = useTranslation();
  const [showTimeZoneModal, setShowTimeZoneModal] = useState<boolean>(false);
  const [showTimeZoneAdvanced, setShowTimeZoneAdvanced] =
    useState<boolean>(false);

  const handleTimeZoneChange = async (e: SelectChangeEvent<string>) => {
    await onTimeZoneChange(e.target.value);
    setShowTimeZoneModal(false);
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{mr: 1, mb: 1}}
        onClick={() => setShowTimeZoneModal(true)}>
        {t('video.updateTimezone')}: {getTimeZoneString(timeZone ?? 'ET')}
      </Button>
      <Modal
        open={showTimeZoneModal}
        onClose={() => {
          setShowTimeZoneModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={styles.modal}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              {t('video.timezone')}
            </FormLabel>
            <RadioGroup
              onChange={handleTimeZoneChange}
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={timeZone}
              name="radio-buttons-group">
              {!showTimeZoneAdvanced ? (
                <>
                  {basicTimezones.map(tz => (
                    <FormControlLabel
                      value={tz.abbr}
                      control={
                        <Radio checked={tz.regex.test(timeZone ?? '')} />
                      }
                      label={getTimeZoneString(tz.abbr)}
                    />
                  ))}
                </>
              ) : (
                <>
                  {advanceTimezones.map(tz => (
                    <FormControlLabel
                      key={tz}
                      value={tz}
                      control={<Radio />}
                      label={getTimeZoneString(tz)}
                    />
                  ))}
                </>
              )}
            </RadioGroup>
          </FormControl>
          <Box>
            <Button
              onClick={() => setShowTimeZoneAdvanced(!showTimeZoneAdvanced)}>
              {t('general.advanced')}
            </Button>
          </Box>
          <Typography variant="body1" sx={{mt: 2}}>
            {t('video.updateTimezoneInstructions')}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default TimeZoneButton;
