import {Box, styled, SxProps, Typography} from '@mui/material';
import {DateTime} from 'luxon';
import React from 'react';
import {textColorGray} from '../../styles';

const SessionDate = styled(Typography)(() => ({
  color: textColorGray,
  marginBottom: 5,
}));

const SessionType = styled(Typography)(() => ({
  textTransform: 'uppercase',
  marginBottom: 5,
}));

const SessionLength = styled(Typography)(() => ({
  color: textColorGray,
}));

interface Props {
  startTime?: Date;
  endTime?: Date;
  type: string;
  sxTitleAndLengthBox?: SxProps;
}

const SessionInfo: React.FC<Props> = ({
  startTime,
  endTime,
  type,
  sxTitleAndLengthBox,
}) => (
  <Box>
    {startTime && (
      <SessionDate variant="body1">
        {DateTime.fromJSDate(startTime).toFormat('EEEE M/d/yyyy h:mm a')}
      </SessionDate>
    )}
    <Box sx={sxTitleAndLengthBox}>
      <SessionType variant="h4">
        <em>{type}</em>
      </SessionType>
      {startTime && endTime && (
        <SessionLength variant="body2">
          {`${Math.ceil(
            DateTime.fromJSDate(endTime).diff(
              DateTime.fromJSDate(startTime),
              'minutes',
            ).minutes,
          )} min`}
        </SessionLength>
      )}
    </Box>
  </Box>
);

export default SessionInfo;

SessionInfo.defaultProps = {
  sxTitleAndLengthBox: {},
};
