import {
  Paper,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import React from 'react';
import PlayerSessionListItem from './PlayerSessionListItem';
import { compare } from '../../helpers';
import { positions } from '../../services/teamService';

interface Props {
  navigateUrlPrefix: string;
  playerSessions: any[];
  showProfile?: boolean
  showDate?: boolean;
  sortBy?: 'default' | 'teamPositionThenNameAsc';
}

const PlayerSessionsList: React.FC<Props> = ({
  navigateUrlPrefix,
  playerSessions,
  showProfile,
  showDate,
  sortBy,
}) => {
  return (
    <TableContainer sx={{mt: 3, mb: 5}} component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          {[...(playerSessions || [])]
            .sort((a: any, b: any) => {
              if (sortBy === 'teamPositionThenNameAsc') {
                return compare(positions.indexOf(a.player?.profile?.position), positions.indexOf(b.player?.profile?.position))
                  || compare(a.player?.profile?.lastName, b.player?.profile?.lastName)
              } else {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return (
                  new Date(b.startTime).getTime() -
                  new Date(a.startTime).getTime()
                );
              }
            })
            .map((playerSession: any) => (
              <PlayerSessionListItem
                key={playerSession.id}
                navigateUrl={`${navigateUrlPrefix}/${playerSession.id}`}
                profileInfo={showProfile ? {
                  firstName: playerSession.player?.profile?.firstName,
                  lastName: playerSession.player?.profile?.lastName,
                  position: playerSession.player?.profile?.position,
                  profilePictureUrl: playerSession.player?.profile?.profilePictureFileKey,
                } : undefined}
                type={playerSession.type}
                hasPlayerData={playerSession.hasPlayerData}
                startTime={showDate ? new Date(playerSession.startTime) : undefined}
                endTime={showDate ? new Date(playerSession.endTime) : undefined}
                activeTime={playerSession.activeTime}
                strideCount={playerSession.strideCountLeft + playerSession.strideCountRight}
                hustleScore={playerSession.hustleScore}
                points={playerSession.points}
              />
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PlayerSessionsList;
