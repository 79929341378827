import { Alert, Box, Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { veoMatchIdentifierRegex } from '../../../services/videoService';
import VideoTypeHeader from './VideoTypeHeader';
import { VideoTypes } from '../../../enums';
import AddVideoWrapper from './AddVideoWrapper';

interface Props {
  setShowModal: (showModal: boolean) => void;
  createSegment: (params: {fileName?: string; parts?: number, veoUrl?: string}) => any;
  processVideo: () => void;
  refresh: () => void;
}

const AddVeoVideo: React.FC<Props> = ({
  setShowModal,
  createSegment,
  processVideo,
  refresh,
}) => {
  const {t} = useTranslation();

  const [veoUrl, setVeoUrl] = useState<string>('');
  const [isValidVeoUrl, setIsValidVeoUrl] = useState<boolean>(false);

  useEffect(() => {
    setIsValidVeoUrl(!!veoMatchIdentifierRegex.exec(veoUrl));
  }, [veoUrl])

  return (
    <AddVideoWrapper type={VideoTypes.veo}>
      <VideoTypeHeader type={VideoTypes.veo} />
      {veoUrl.length > 0 && !isValidVeoUrl && (
        <Alert
          severity="error"
          variant="outlined"
          sx={{mb: 1}}
        >
          {t('video.videoTypes.veo.invalidUrlError')}
        </Alert>
      )}
      <TextField
        label="Veo URL"
        sx={{width: '100%', mt: 3, mb: 3}}
        onChange={(e) => setVeoUrl(e.target.value)}
        value={veoUrl}
      />
      <Box
        sx={{textAlign: 'right'}}
      >
        <Button
          variant="contained"
          disabled={!isValidVeoUrl}
          onClick={async () => {
            await createSegment({
              veoUrl,
            });
            await processVideo();
            await refresh();
            setShowModal(false);
          }}
        >
          {t('general.submit')}
        </Button>
      </Box>
    </AddVideoWrapper>
  );
};

export default AddVeoVideo;
