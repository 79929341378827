import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

interface Props {
  teams: any[];
}

const TeamList: React.FC<Props> = ({
  teams,
}) => {
  const {t} = useTranslation();

  return (
    <TableContainer sx={{mb: 1}} component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          {[...(teams ?? [])].map((team: any) => (
            <TableRow
              key={team.id}
            >
              <TableCell>
                <Typography variant="h4">
                  <em>{team.name}</em>
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Button component={NavLink} to={`/teams/${team.id}/teamsessions`}>
                  {t('general.teamSessions')}
                </Button>
                <Button component={NavLink} to={`/teams/${team.id}/players`}>
                  {t('general.players')}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TeamList;
