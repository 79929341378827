import { AxiosPromise } from 'axios';
import api from './api';

export function getCurrentProfile() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get('/profiles/current')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getProfileDetail({profileId}: {
  profileId: number;
}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get(`/profiles/${profileId}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function updateProfileMembership({
  profileId,
  membershipLevel,
}: {
  profileId?: number;
  membershipLevel: string;
}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .put(`/profiles/${profileId ?? 'current'}/membership`, {membershipLevel})
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getProfileMembership({profileId}: {
  profileId?: number;
}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get(`/profiles/${profileId ?? 'current'}/membership`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function updateProfile({
  profileId,
  profileData,
}: {
  profileId?: number;
  profileData: any;
}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .put(`/profiles/${profileId ?? 'current'}`, profileData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}
