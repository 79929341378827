import { Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';


interface Props {
  children?: ReactNode;
}

const StyledTypography = styled(Typography)(() => ({
  textTransform: 'uppercase',
  marginBottom: 20,
}));

const PageTitle: React.FC<Props> = ({children}) => {
  return (
      <StyledTypography
        variant='h1'
      >
        <em>{children}</em>
      </StyledTypography>
  )
};

export default PageTitle;
