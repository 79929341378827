import {DayNames} from '../enums';
import i18next from 'i18next';

function getTime(secs?: number, includeMs: boolean = false) {
  let minutes = 0;
  let seconds = 0;
  let milliseconds = null;

  if (secs === undefined || secs === null) {
    return null;
  }

  minutes = Math.floor(secs / 60);
  seconds = Math.floor(secs % 60);
  milliseconds = (secs % 1).toFixed(3).substring(2, 5);

  if (minutes <= 60) {
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}${
      includeMs ? `:${milliseconds}` : ''
    }`;
  }

  const hours = Math.floor(minutes / 60);
  minutes = Math.floor(minutes % 60);

  return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
    seconds < 10 ? '0' : ''
  }${seconds}${includeMs ? `:${milliseconds}` : ''}`;
}

function getTimeZoneString(abbreviation: string) {
  switch (abbreviation) {
    case 'AT':
      return i18next.t('timezones.AT');
    case 'ET':
      return i18next.t('timezones.ET');
    case 'CT':
      return i18next.t('timezones.CT');
    case 'MT':
      return i18next.t('timezones.MT');
    case 'PT':
      return i18next.t('timezones.PT');
    case 'AKT':
      return i18next.t('timezones.AKT');
    case 'HT':
      return i18next.t('timezones.HT');
    case 'AST':
      return i18next.t('timezones.AST');
    case 'ADT':
      return i18next.t('timezones.ADT');
    case 'EST':
      return i18next.t('timezones.EST');
    case 'EDT':
      return i18next.t('timezones.EDT');
    case 'CST':
      return i18next.t('timezones.CST');
    case 'CDT':
      return i18next.t('timezones.CDT');
    case 'MST':
      return i18next.t('timezones.MST');
    case 'MDT':
      return i18next.t('timezones.MDT');
    case 'PST':
      return i18next.t('timezones.PST');
    case 'PDT':
      return i18next.t('timezones.PDT');
    case 'AKST':
      return i18next.t('timezones.AKST');
    case 'AKDT':
      return i18next.t('timezones.AKDT');
    case 'HST':
      return i18next.t('timezones.HST');
    case 'HDT':
      return i18next.t('timezones.HDT');
    default:
      return i18next.t('general.unknown');
  }
}

function getDayName(index: number) {
  switch (index) {
    case 0:
      return DayNames.sunday;
    case 1:
      return DayNames.monday;
    case 2:
      return DayNames.tuesday;
    case 3:
      return DayNames.wednesday;
    case 4:
      return DayNames.thursday;
    case 5:
      return DayNames.friday;
    case 6:
      return DayNames.saturday;
    default:
      return '';
  }
}

export {getTime, getTimeZoneString, getDayName};
