import {Auth} from 'aws-amplify';
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_GRAPHQL_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// add a request interceptor with bearer token
api.interceptors.request.use(async config => {
  config.headers = config.headers || {};
  config.headers.Authorization = `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`;
  return config;
});

export default api;
