import {Box, Button, Grid, Typography} from '@mui/material';
import React from 'react';
import {VideoTypes} from '../../../enums';
import {useTranslation} from 'react-i18next';
import {videoTypes} from '../../../services/videoService';

interface Props {
  selectVideoType: (videoType: VideoTypes) => void;
}

const VideoTypeSelection: React.FC<Props> = ({selectVideoType}) => {
  const {t} = useTranslation();

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {t('video.videoTypesInstructions')}
      </Typography>
      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <Grid container spacing={1} sx={{maxWidth: 400}}>
          {videoTypes.map(videoType => (
            <Grid key={videoType.name} item xs={4}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => selectVideoType(videoType.type)}
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                }}>
                <Box
                  sx={{
                    width: '100%',
                    pb: '100%',
                    position: 'relative',
                  }}>
                  <Box
                    sx={{
                      position: 'absolute',
                      height: '100%',
                      width: '100%',
                      p: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    {videoType.image ? (
                      <img
                        src={videoType.image}
                        alt={videoType.name}
                        style={{
                          maxWidth: videoType.width ?? '100%',
                          maxHeight: '100%',
                        }}
                      />
                    ) : (
                      <Typography variant="h3" color="#fff">
                        {videoType.name}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default VideoTypeSelection;
