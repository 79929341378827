import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Dashboard from './components/Dashboard';
import BaseLayout from './components/layouts/BaseLayout';
import Membership from './components/Membership/Membership';
import MembershipPro from './components/Membership/MembershipPro';
import PageNotFound from './components/PageNotFound';
import Admin from './components/Admin';
import PlayerSessionDetail from './components/PlayerSessions/PlayerSessionDetail';
import PlayerSessions from './components/PlayerSessions/PlayerSessions';
import PlayerSessionVideoDetail from './components/PlayerSessions/PlayerSessionVideoDetail';
import FullScreenLoadingSpinner from './components/shared/FullScreenLoading';
import usePageTracking from './hooks/usePageTracking';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { getCurrentProfileAsync } from './store/profileSlice';
import { getCurrentFamiliesAsync } from './store/familySlice';
import { getCurrentTeamsAsync } from './store/teamSlice';
import TeamSessionDetail from './components/TeamSessions/TeamSessionDetail';
import TeamSessionVideoDetail from './components/TeamSessions/TeamSessionVideoDetail';
import TeamSessions from './components/TeamSessions/TeamSessions';
import TeamSessionGroupDetail from './components/TeamSessions/TeamSessionGroupDetail';
import TeamPlayers from './components/Team/TeamPlayers';
import TeamReports from './components/Team/TeamReports';
import Settings from './components/Settings';

interface Props {}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const AppRoutes: React.FC = () => {
  usePageTracking();

  return (
    <SentryRoutes>
      <Route path="/" element={<Dashboard/>} />
      <Route path="/admin" element={<Admin/>} />
      <Route path="/playersessions" element={<PlayerSessions/>} />
      <Route path="/playersessions/:playerSessionId" element={<PlayerSessionDetail/>} />
      <Route path="/playersessions/:playerSessionId/sessionvideos/:videoId" element={<PlayerSessionVideoDetail/>} />
      <Route path="/teams/:teamId/teamsessions" element={<TeamSessions />} />
      <Route path="/teams/:teamId/teamsessions/:teamSessionId" element={<TeamSessionDetail/>} />
      <Route path="/teams/:teamId/teamsessions/:teamSessionId/playersessions/:playerSessionId" element={<PlayerSessionDetail />} />
      <Route path="/teams/:teamId/teamsessions/:teamSessionId/sessionvideos/:videoId" element={<TeamSessionVideoDetail/>} />
      <Route path="/teams/:teamId/teamsessiongroups/:teamSessionId" element={<TeamSessionGroupDetail/>} />
      <Route path="/teams/:teamId/players" element={<TeamPlayers />} />
      <Route path="/teams/:teamId/reports" element={<TeamReports />} />
      <Route path="/teams/:teamId/players/:profileId/playersessions" element={<PlayerSessions />} />
      <Route path="/teams/:teamId/players/:profileId/playersessions/:playerSessionId" element={<PlayerSessionDetail />} />
      <Route path="/teams/:teamId/players/:profileId/playersessions/:playerSessionId/sessionvideos/:videoId" element={<PlayerSessionVideoDetail/>} />
      <Route path="/membership" element={<Membership/>} />
      <Route path="/membership/pro" element={<MembershipPro/>} />
      <Route path="/family/children/:profileId/playersessions" element={<PlayerSessions/>} />
      <Route path="/family/children/:profileId/playersessions/:playerSessionId" element={<PlayerSessionDetail/>} />
      <Route path="/family/children/:profileId/playersessions/:playerSessionId/sessionvideos/:videoId" element={<PlayerSessionVideoDetail/>} />
      <Route path="/family/children/:profileId/membership" element={<Membership/>} />
      <Route path="/family/children/:profileId/membership/pro" element={<MembershipPro/>} />
      <Route path="/settings" element={<Settings />} />
      <Route path="*" element={<PageNotFound />} />
    </SentryRoutes>
  );
}

const AppRouter: React.FC<Props> = (props) => {
  const currentState = useAppSelector((state) => ({
    profile: state.profile,
  }));
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCurrentProfileAsync());
    dispatch(getCurrentFamiliesAsync());
    dispatch(getCurrentTeamsAsync());
  }, []);

  if (currentState.profile.profile) {
    return (
      <BrowserRouter>
        <BaseLayout>
          <AppRoutes />
        </BaseLayout>
      </BrowserRouter>
    )
  }

  return (
    <FullScreenLoadingSpinner />
  );
}

export default AppRouter;
