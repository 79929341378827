import {AxiosPromise} from "axios";
import api from "./api";

export function getDevices(params: {
  profileId?: number;
}) {
  const {profileId} = params;

  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get(`/devices${profileId ? (`?profileId=${profileId}`) : ''}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}