import { Box, Button, CircularProgress, Paper, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { postAdminUtilAsync } from '../../store/adminSlice';

interface Props {}

const UnpairTeamDevices: React.FC<Props> = (props) => {
  const {t} = useTranslation();
  const currentState = useAppSelector((state) => ({
    admin: state.admin,
  }));
  const dispatch = useAppDispatch(); 

  const [teamId, setTeamId] = React.useState<string>('');

  const handleSubmit = () => {
    if (!teamId) {
      alert('Please enter a team ID');
    } else {
      const confirm = window.confirm(`Are you sure you want to unpair all devices from team ${teamId}?`);
      if (confirm) {
        dispatch(
          postAdminUtilAsync({
            job: 'unpairTeamDevices',
            data: {
              teamId: parseInt(teamId,10),
            },
            onSuccess: () => {
              alert(`Successfully unpaired all devices from team ${teamId}`);
            },
            onError: () => {
              alert(`Error unpairing all devices from team ${teamId}`);
            }
          }),
        );
      }
    }
  }

  return (
    <Paper sx={{p: 5}}>
      <Typography variant="body1" sx={{mb: 3}}>
        {t('admin.unpairTeamDevicesInfo')}
      </Typography>
      <TextField
        label="Team ID"
        sx={{width: '100%', mb: 3}}
        onChange={(e) => setTeamId(e.target.value)}
        value={teamId}
      />
      <Box sx={{textAlign: 'right'}}>
        <Button
          variant='contained'
          disabled={!Number.isInteger(parseInt(teamId, 10)) || teamId === '6'}
          onClick={handleSubmit}>
            Submit
            {currentState?.admin?.status === 'loading' ? (
              <CircularProgress
                sx={{color: '#fff', ml: 1}}
                size={16}
              />
            ) : null}
        </Button>
      </Box>
    </Paper>
  );
};

export default UnpairTeamDevices;
