import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../store/hooks';
import { Helmet } from 'react-helmet';
import { Container, Grid } from '@mui/material';
import { Roles } from '../enums';
import PageTitle from './shared/PageTitle';
import UnpairTeamDevices from './Admin/UnpairTeamDevices';
import FetchMembershipProDestination from './Admin/FetchMembershipProDestination';
import ResendShopifyMembershipKey from './Admin/ResendShopifyMembershipKey';

interface Props {}

const Admin: React.FC<Props> = (props) => {
  const {t} = useTranslation();
  const currentState = useAppSelector((state) => ({
    profile: state.profile,
    playerSession: state.playerSession,
    team: state.team,
    family: state.family,
  }));

  return (
    <>
      <Helmet>
        <title>{t('navigation.pageTitle', {pageName: t('pages.admin')})}</title>
      </Helmet>
      <Container>
        {(currentState?.profile?.profile?.role === Roles.admin) && (
          <>
            <PageTitle>{t('pages.admin')}</PageTitle>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <UnpairTeamDevices />
              </Grid>
              <Grid item xs={12} md={6}>
                <FetchMembershipProDestination />
              </Grid>
              <Grid item xs={12} md={6}>
                <ResendShopifyMembershipKey />
              </Grid>
            </Grid>
          </>
        )}
        {(currentState?.profile?.profile?.role !== Roles.admin) && (
          <>
            <PageTitle>{t('general.forbidden')}</PageTitle>
          </>
        )}
      </Container>
    </>
  )
}

export default Admin;
