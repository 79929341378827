import {Box, Button, Container, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useAppSelector} from '../../store/hooks';
import FullScreenLoadingSpinner from '../shared/FullScreenLoading';
import PageTitle from '../shared/PageTitle';
import TeamPlayerList from '../shared/TeamPlayerList';
import {
  getTeamActiveDeviceGroups,
  updateTeamActiveDeviceGroup,
} from '../../api/teamDeviceActiveGroupAPI';

interface Props {}

const TeamPlayers: React.FC<Props> = () => {
  const {t} = useTranslation();
  const currentState = useAppSelector(state => ({
    team: state.team,
    family: state.family,
  }));
  const [groups, setGroups] = useState<{name: string; count: number}[]>([]);

  const {teamId} = useParams();
  const teamIdInt = parseInt(teamId || '');

  const activeTeam = (currentState?.team?.teams ?? []).filter(
    team => team.id === teamIdInt,
  );

  const updateTeamActiveDeviceGroupCounts = async (teamId: number) => {
    const res = await getTeamActiveDeviceGroups(teamId);
    const resGroups = res.data?.data?.getTeamActiveDeviceGroups?.groups ?? [];
    if (resGroups.length > 0) {
      setGroups(resGroups);
    }
  };

  useEffect(() => {
    if (teamIdInt === 71) {
      updateTeamActiveDeviceGroupCounts(71);
    } else if (teamIdInt === 81) {
      updateTeamActiveDeviceGroupCounts(81);
    }
  }, []);

  const title = t('navigation.pageTitle', {pageName: t('pages.teamPlayers')});

  if (currentState.team?.status === 'loading' || activeTeam.length !== 1) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <FullScreenLoadingSpinner />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <PageTitle>{t('pages.teamPlayers')}</PageTitle>
        {/* hide back button until we create a coach dashboard screen */}
        {/* <BackButton navigationUrl="/">
          {t('navigation.back', {pageName: t('pages.dashboard')})}
        </BackButton> */}
        {activeTeam[0]?.id === 71 && (
          <>
            <Box>
              {groups.map(group => (
                <Typography key={group.name} variant="body1">
                  {group.name} Device Count: {group.count}
                </Typography>
              ))}
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                sx={{mr: 1, mb: 1}}
                onClick={async () => {
                  await updateTeamActiveDeviceGroup(71, 'MiteSquirt');
                  await updateTeamActiveDeviceGroupCounts(71);
                }}>
                Activate Devices For Group Mite & Squirt
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{mr: 1, mb: 1}}
                onClick={async () => {
                  await updateTeamActiveDeviceGroup(71, 'PeeweeBantam');
                  await updateTeamActiveDeviceGroupCounts(71);
                }}>
                Activate Devices For Group Peewee & Bantam
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{mr: 1, mb: 1}}
                onClick={async () => {
                  await updateTeamActiveDeviceGroup(71, 'Midget');
                  await updateTeamActiveDeviceGroupCounts(71);
                }}>
                Activate Devices For Group Midget
              </Button>
            </Box>
          </>
        )}
        {activeTeam[0]?.id === 81 && (
          <>
            <Box>
              {groups.map(group => (
                <Typography key={group.name} variant="body1">
                  {group.name} Device Count: {group.count}
                </Typography>
              ))}
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                sx={{mr: 1, mb: 1}}
                onClick={async () => {
                  await updateTeamActiveDeviceGroup(81, 'U7U9');
                  await updateTeamActiveDeviceGroupCounts(81);
                }}>
                Activate Devices For Group U7-U9
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{mr: 1, mb: 1}}
                onClick={async () => {
                  await updateTeamActiveDeviceGroup(81, 'Ringette');
                  await updateTeamActiveDeviceGroupCounts(81);
                }}>
                Activate Devices For Group Ringette
              </Button>
            </Box>
          </>
        )}
        <TeamPlayerList
          players={activeTeam[0].players}
          teamId={activeTeam[0].id}
        />
      </Container>
    </>
  );
};

export default TeamPlayers;
