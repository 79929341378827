import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  familyMembers: any[];
  showPlayerSessionsLink?: boolean;
  showMembershipLink?: boolean;
  showMembershipLinkReload?: boolean;
}

const FamilyMemberList: React.FC<Props> = ({
  familyMembers,
  showPlayerSessionsLink,
  showMembershipLink,
  showMembershipLinkReload,
}) => {
  return (
    <TableContainer sx={{mb: 1}} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...(familyMembers ?? [])].map((child: any) => (
            <TableRow
              key={child.id}
            >
              <TableCell>{`${child.firstName} ${child.lastName}`}</TableCell>
              <TableCell align="right">
                {showPlayerSessionsLink && (
                  <Button component={NavLink} to={`/family/children/${child.id}/playersessions`}>
                    Player Sessions
                  </Button>
                )}
                {showMembershipLink && (
                  <Button component={NavLink} to={`/family/children/${child.id}/membership`}>
                    Membership
                  </Button>
                )}
                {showMembershipLinkReload && (
                  <Button onClick={() => {
                    window.location.pathname = `/family/children/${child.id}/membership`;
                  }}>
                    Membership
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

export default FamilyMemberList;
