import React from 'react';
import {Box, styled} from '@mui/material';

interface Props {
  children: React.ReactNode;
}

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
}));

const SubtitleContainer: React.FC<Props> = ({children}) => {
  return <Container>{children}</Container>;
};

export default SubtitleContainer;
