import {Box, Button} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import React, {useRef} from 'react';
import PlayerSessionShiftCard from './PlayerSessionShiftCard';
import {PlayerSessionShift} from '../../interfaces';
import {isNullOrUndefined} from '../../helpers';

interface Props {
  shiftClips: PlayerSessionShift[];
  videoIndex: number;
  setVideoIndex: (index: number) => void;
  videoRef: React.RefObject<HTMLVideoElement>;
  solidButtons?: boolean;
}

const PlayerSessionShiftSlider: React.FC<Props> = ({
  shiftClips,
  videoIndex,
  setVideoIndex,
  videoRef,
  solidButtons,
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  const startScrollLeft = () => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      const currentScrollLeftPosition = sliderRef.current?.scrollLeft;
      sliderRef.current?.scrollTo((currentScrollLeftPosition ?? 0) - 5, 0);
    }, 10);
  };

  const stopScrollLeft = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const startScrollRight = () => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      const currentScrollLeftPosition = sliderRef.current?.scrollLeft;
      sliderRef.current?.scrollTo((currentScrollLeftPosition ?? 0) + 5, 0);
    }, 10);
  };

  const stopScrollRight = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}>
        <Button
          variant={solidButtons ? 'contained' : 'outlined'}
          sx={{mr: 1, display: {xs: 'none', md: 'flex'}}}
          onMouseEnter={startScrollLeft}
          onMouseLeave={stopScrollLeft}
          onTouchStart={startScrollLeft}
          onTouchEnd={stopScrollLeft}>
          <ArrowBackIosNewIcon />
        </Button>
        <Box
          ref={sliderRef}
          sx={{
            flex: 1,
            display: 'flex',
            overflow: 'auto',
            scrollbar: 'none', // chrome
            overflowStyle: 'none', // IE and Edge
            scrollbarWidth: 'none', // Firefox
          }}>
          <Box
            sx={{
              display: 'flex',
            }}>
            {shiftClips.map((shift, index: number) => (
              <PlayerSessionShiftCard
                key={shift.id}
                sx={{width: 250, mx: 1}}
                shiftNumber={index + 1}
                active={index === videoIndex}
                startTime={shift.startTime}
                activeTime={
                  !isNullOrUndefined(shift.end) &&
                  !isNullOrUndefined(shift.start)
                    ? (shift.end as number) - (shift.start as number)
                    : undefined
                }
                strintTotalTime={shift.strintTotalTime}
                strideCountTotal={shift.strideCountTotal}
                onClick={() => {
                  setVideoIndex(index);
                  videoRef.current?.load();
                }}
              />
            ))}
          </Box>
        </Box>
        <Button
          variant={solidButtons ? 'contained' : 'outlined'}
          sx={{ml: 1, display: {xs: 'none', md: 'flex'}}}
          onMouseEnter={startScrollRight}
          onMouseLeave={stopScrollRight}
          onTouchStart={startScrollRight}
          onTouchEnd={stopScrollRight}>
          <ArrowForwardIosIcon />
        </Button>
      </Box>
      <Box sx={{display: {xs: 'flex', md: 'none'}, mt: 1}}>
        <Button
          variant={solidButtons ? 'contained' : 'outlined'}
          sx={{mr: 1, flexGrow: 1}}
          onTouchStart={startScrollLeft}
          onTouchEnd={stopScrollLeft}>
          <ArrowBackIosNewIcon />
        </Button>
        <Button
          variant={solidButtons ? 'contained' : 'outlined'}
          sx={{ml: 1, flexGrow: 1}}
          onTouchStart={startScrollRight}
          onTouchEnd={stopScrollRight}>
          <ArrowForwardIosIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default React.memo(PlayerSessionShiftSlider);
