import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {useLocation} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InfoIcon from '@mui/icons-material/Info';
import TimeZoneButton from './TimeZoneButton';
import styles from '../../styles';
import {getTime} from '../../services/dateService';
import {useTranslation} from 'react-i18next';
import {
  livebarnRegex,
  getFilenamesError,
  getFilenamesMessage,
  getLivebarnMissingSegmentWarning,
} from '../../services/videoService';
import VideoTypeSelection from './VideoManagement/VideoTypeSelection';
import {VideoTypes} from '../../enums';
import AddIosVideo from './VideoManagement/AddIosVideo';
import AddVeoVideo from './VideoManagement/AddVeoVideo';
import AddAndroidVideo from './VideoManagement/AddAndroidVideo';
import AddScoreCamVideo from './VideoManagement/AddScoreCamVideo';
import AddLiveBarnVideo from './VideoManagement/AddLiveBarnVideo';
import AddPixellotVideo from './VideoManagement/AddPixellotVideo';
import AddMp4Video from './VideoManagement/AddMp4Video';
import Mp4AnchorSelection from './VideoManagement/Mp4AnchorSelection';
import {SessionEvent, SessionVideoSegment} from '../../interfaces';

const MINIMUM_VIDEO_SEGMENT_DURATION = 60;

interface Props {
  timeZone?: string;
  onTimeZoneChange: (timeZone: string) => void;
  sessionVideoSegments: SessionVideoSegment[];
  refresh: () => void;
  createSegment: (params: {
    fileName?: string;
    parts?: number;
    veoUrl?: string;
    pixellotUrl?: string;
    userSelectedStartTime?: Date;
  }) => any;
  createSegmentComplete: (params: {
    uploadId: string;
    key: string;
    parts: object[];
  }) => void;
  deleteSegment: (id: number) => void;
  processVideo: () => void;
  isProcessing: boolean;
  sessionEvents: SessionEvent[];
}

const VideoManagement: React.FC<Props> = ({
  timeZone,
  onTimeZoneChange,
  sessionVideoSegments,
  refresh,
  createSegment,
  createSegmentComplete,
  deleteSegment,
  processVideo,
  isProcessing,
  sessionEvents,
}) => {
  const {t} = useTranslation();
  const {hash} = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [videoType, setVideoType] = useState<VideoTypes | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [
    showMp4ModalSessionVideoSegmentId,
    setShowMp4ModalSessionVideoSegmentId,
  ] = useState<number | null>(null);
  const [reprocessStarted, setReprocessStarted] = useState<boolean>(false);
  const [alreadyUploadedFilenamesError, setAlreadyUploadedFilenamesError] =
    useState<string | null>(null);
  const [alreadyUploadedFilenamesMessage, setAlreadyUploadedFilenamesMessage] =
    useState<string | null>(null);
  const [
    alreadyUploadedLivebarnMissingSegmentWarning,
    setAlreadyUploadedLivebarnMissingSegmentWarning,
  ] = useState<string | null>(null);

  const livebarnFilenameWarning =
    sessionVideoSegments.filter(
      segment =>
        segment.type === 'livebarn' &&
        !livebarnRegex.exec(segment.fileName as string),
    ).length > 0;
  const multipleDimensionsError =
    [
      ...new Set(
        sessionVideoSegments
          .map(segment => segment.width)
          .filter(width => !!width),
      ),
    ].length > 1 ||
    [
      ...new Set(
        sessionVideoSegments
          .map(segment => segment.height)
          .filter(height => !!height),
      ),
    ].length > 1;
  const durationError =
    sessionVideoSegments.filter(
      segment =>
        (segment.duration as number) > 0 &&
        (segment.duration as number) < MINIMUM_VIDEO_SEGMENT_DURATION,
    ).length > 0;
  const unknownTypeError =
    sessionVideoSegments.filter(
      segment =>
        !!segment.type &&
        ['ios', 'android', 'livebarn', 'veo', 'pixellot', 'mp4'].indexOf(
          segment.type,
        ) === -1,
    ).length > 0;

  useEffect(() => {
    if (hash === '#add-video') {
      setShowModal(true);
    }
  }, [hash]);

  useEffect(() => {
    setAlreadyUploadedFilenamesError(
      sessionVideoSegments
        ? getFilenamesError(
            [...sessionVideoSegments].map(
              file =>
                (file.fileName as string) ??
                (file.veoUrl as string) ??
                (file.pixellotUrl as string),
            ),
          )
        : null,
    );
    setAlreadyUploadedFilenamesMessage(
      sessionVideoSegments
        ? getFilenamesMessage(
            [...sessionVideoSegments].map(file => file.fileName as string),
          )
        : null,
    );
    setAlreadyUploadedLivebarnMissingSegmentWarning(
      sessionVideoSegments
        ? getLivebarnMissingSegmentWarning(
            [...sessionVideoSegments].map(file => file.fileName as string),
          )
        : null,
    );
  }, [sessionVideoSegments]);

  const handleDeleteAll = async () => {
    if (window.confirm(t('video.deleteAllConfirm')!)) {
      await Promise.all(
        [...sessionVideoSegments].map(segment => {
          return deleteSegment(segment.id as number);
        }),
      );
      refresh();
    }
  };

  return (
    <Box sx={{mt: 3}}>
      <Typography variant="body1" sx={{mb: 2}}>
        {t('video.instructions')}
      </Typography>
      <TableContainer sx={{mb: 1}} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="right">Duration</TableCell>
              <TableCell align="right">Dimensions</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...sessionVideoSegments]
              .sort((a, b) =>
                (a.fileName as string) > (b.fileName as string) ? 1 : -1,
              )
              .map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.fileName ?? row.veoUrl ?? row.pixellotUrl}
                  </TableCell>
                  <TableCell align="center">
                    {!!row.key || !!row.veoUrl || !!row.pixellotUrl ? (
                      <CheckIcon />
                    ) : (
                      <CloudUploadIcon />
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {row.duration ? getTime(row.duration) : row.duration}
                  </TableCell>
                  <TableCell align="right">
                    {row.width && row.height
                      ? `${row.width} x ${row.height}`
                      : null}
                  </TableCell>
                  <TableCell align="center">
                    {[
                      'ios',
                      'android',
                      'livebarn',
                      'veo',
                      'pixellot',
                      'mp4',
                    ].indexOf(row.type as string) >= 0
                      ? row.type
                      : 'unknown'}
                  </TableCell>
                  <TableCell align="right">
                    {row.type === 'mp4' && (
                      <>
                        <Button
                          disabled={(sessionEvents ?? []).length === 0}
                          onClick={async () => {
                            setShowMp4ModalSessionVideoSegmentId(
                              row.id as number,
                            );
                          }}>
                          {t('video.alignSessionEvent')}
                        </Button>
                        <Mp4AnchorSelection
                          sessionVideoSegmentId={row.id as number}
                          showModal={
                            showMp4ModalSessionVideoSegmentId === row.id
                          }
                          setShowModal={setShowMp4ModalSessionVideoSegmentId}
                          previewUrl={row.videoUrl as string}
                          sessionEvents={sessionEvents}
                          refresh={refresh}
                          processVideo={processVideo}
                        />
                      </>
                    )}
                    <Button
                      onClick={async () => {
                        await deleteSegment(row.id as number);
                        refresh();
                      }}>
                      {t('general.delete')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {sessionVideoSegments.filter(
        segment => !segment.key && !segment.veoUrl && !segment.pixellotUrl,
      ).length > 0 && (
        <Alert severity="error" variant="outlined" sx={{mb: 1}}>
          {t('video.incompleteUploadError')}
        </Alert>
      )}
      {livebarnFilenameWarning && (
        <Alert severity="error" variant="outlined" sx={{mb: 1}}>
          {t('video.videoTypes.livebarn.invalidFilenameError')}
        </Alert>
      )}
      {multipleDimensionsError && (
        <Alert severity="error" variant="outlined" sx={{mb: 1}}>
          {t('video.multipleDimensionsError')}
        </Alert>
      )}
      {durationError && (
        <Alert severity="error" variant="outlined" sx={{mb: 1}}>
          {t('video.durationError')}
        </Alert>
      )}
      {unknownTypeError && (
        <Alert severity="error" variant="outlined" sx={{mb: 1}}>
          {t('video.unknownTypeError')}
        </Alert>
      )}

      {alreadyUploadedFilenamesError ? (
        <Alert severity="error" variant="outlined" sx={{mb: 1}}>
          {alreadyUploadedFilenamesError}
        </Alert>
      ) : null}
      {alreadyUploadedLivebarnMissingSegmentWarning ? (
        <Alert severity="warning" variant="outlined" sx={{mb: 1}}>
          {alreadyUploadedLivebarnMissingSegmentWarning}
        </Alert>
      ) : null}
      {alreadyUploadedFilenamesMessage ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}>
          <InfoIcon htmlColor="#fff" sx={{mr: 1}} />
          <Typography variant="body1" sx={{mb: 1}}>
            {alreadyUploadedFilenamesMessage}
          </Typography>
        </Box>
      ) : null}
      <Button
        variant="contained"
        sx={{mr: 1, mb: 1}}
        onClick={() => {
          setVideoType(null);
          setShowModal(true);
        }}>
        {t('video.addSessionVideos')}
      </Button>
      <TimeZoneButton timeZone={timeZone} onTimeZoneChange={onTimeZoneChange} />
      {sessionVideoSegments.length > 0 ? (
        <>
          <Button
            disabled={
              !!alreadyUploadedFilenamesError ||
              sessionVideoSegments.filter(
                segment =>
                  !segment.key && !segment.veoUrl && !segment.pixellotUrl,
              ).length > 0 ||
              multipleDimensionsError ||
              durationError ||
              isProcessing ||
              reprocessStarted
            }
            variant="contained"
            sx={{mr: 1, mb: 1}}
            onClick={() => {
              setReprocessStarted(true);
              processVideo();
            }}>
            Reprocess Session Video
            {(isProcessing || reprocessStarted) && (
              <CircularProgress sx={{color: '#fff', ml: 1}} size={16} />
            )}
          </Button>
          {(isProcessing || reprocessStarted) && (
            <Button
              variant="contained"
              sx={{mr: 1, mb: 1}}
              onClick={() => {
                refresh();
                setTimeout(() => {
                  setReprocessStarted(false);
                }, 2000);
              }}>
              Refresh
            </Button>
          )}
          <Button variant="contained" sx={{mb: 1}} onClick={handleDeleteAll}>
            {t('general.deleteAll')}
          </Button>
        </>
      ) : null}
      <Modal
        open={showModal}
        onClose={() => {
          if (!loading) {
            setShowModal(false);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={styles.modal}>
          {videoType === null && (
            <VideoTypeSelection
              selectVideoType={(videoType: VideoTypes) =>
                setVideoType(videoType)
              }
            />
          )}
          {videoType === VideoTypes.ios && (
            <AddIosVideo
              setShowModal={setShowModal}
              loading={loading}
              setLoading={setLoading}
              createSegment={createSegment}
              createSegmentComplete={createSegmentComplete}
              processVideo={processVideo}
              refresh={refresh}
            />
          )}
          {videoType === VideoTypes.android && (
            <AddAndroidVideo
              setShowModal={setShowModal}
              loading={loading}
              setLoading={setLoading}
              createSegment={createSegment}
              createSegmentComplete={createSegmentComplete}
              processVideo={processVideo}
              refresh={refresh}
            />
          )}
          {videoType === VideoTypes.livebarn && (
            <AddLiveBarnVideo
              setShowModal={setShowModal}
              loading={loading}
              setLoading={setLoading}
              createSegment={createSegment}
              createSegmentComplete={createSegmentComplete}
              processVideo={processVideo}
              refresh={refresh}
            />
          )}
          {videoType === VideoTypes.scorecam && (
            <AddScoreCamVideo
              setShowModal={setShowModal}
              loading={loading}
              setLoading={setLoading}
              createSegment={createSegment}
              createSegmentComplete={createSegmentComplete}
              processVideo={processVideo}
              refresh={refresh}
            />
          )}
          {(videoType === VideoTypes.mp4 ||
            videoType === VideoTypes.flohockey ||
            videoType === VideoTypes.mnhockeytv) && (
            <AddMp4Video
              setShowModal={setShowModal}
              loading={loading}
              setLoading={setLoading}
              createSegment={createSegment}
              createSegmentComplete={createSegmentComplete}
              processVideo={processVideo}
              refresh={refresh}
              sessionEvents={sessionEvents ?? []}
            />
          )}
          {videoType === VideoTypes.veo && (
            <AddVeoVideo
              setShowModal={setShowModal}
              createSegment={createSegment}
              processVideo={processVideo}
              refresh={refresh}
            />
          )}
          {videoType === VideoTypes.pixellot && (
            <AddPixellotVideo
              setShowModal={setShowModal}
              createSegment={createSegment}
              processVideo={processVideo}
              refresh={refresh}
              sessionEvents={sessionEvents ?? []}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default VideoManagement;
