import {Button, Container} from '@mui/material';
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useAppSelector} from '../../store/hooks';
import FullScreenLoadingSpinner from '../shared/FullScreenLoading';
import PageTitle from '../shared/PageTitle';
import {getReport} from '../../api/reportAPI';

interface Props {}

const TeamReports: React.FC<Props> = () => {
  const {t} = useTranslation();
  const currentState = useAppSelector(state => ({
    team: state.team,
    family: state.family,
  }));

  const {teamId} = useParams();
  const teamIdInt = parseInt(teamId || '');

  const activeTeam = (currentState?.team?.teams ?? []).filter(
    team => team.id === teamIdInt,
  );

  useEffect(() => {}, []);

  const title = t('navigation.pageTitle', {pageName: t('pages.teamReports')});

  if (currentState.team?.status === 'loading' || activeTeam.length !== 1) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <FullScreenLoadingSpinner />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <PageTitle>{t('pages.teamReports')}</PageTitle>
        {/* hide back button until we create a coach dashboard screen */}
        {/* <BackButton navigationUrl="/">
          {t('navigation.back', {pageName: t('pages.dashboard')})}
        </BackButton> */}
        <Button
          sx={{mr: 2, mb: 2}}
          variant="contained"
          onClick={() => {
            getReport({
              teamId: teamIdInt,
              reportType: 'timeOnIce',
              reportName: 'time_on_ice',
              teamName: activeTeam[0].name,
            });
          }}>
          Time On Ice
        </Button>
        <Button
          sx={{mr: 2, mb: 2}}
          variant="contained"
          onClick={() => {
            getReport({
              teamId: teamIdInt,
              reportType: 'strideTime',
              reportName: 'stride_time',
              teamName: activeTeam[0].name,
            });
          }}>
          Stride Time
        </Button>
        <Button
          sx={{mr: 2, mb: 2}}
          variant="contained"
          onClick={() => {
            getReport({
              teamId: teamIdInt,
              reportType: 'shiftCount',
              reportName: 'shift_count',
              teamName: activeTeam[0].name,
            });
          }}>
          Shift Count
        </Button>
        <Button
          sx={{mr: 2, mb: 2}}
          variant="contained"
          onClick={() => {
            getReport({
              teamId: teamIdInt,
              reportType: 'energyRatio',
              reportName: 'energy_ratio',
              teamName: activeTeam[0].name,
            });
          }}>
          Energy Ratio
        </Button>
      </Container>
    </>
  );
};

export default React.memo(TeamReports);
