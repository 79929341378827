import {TFunction} from 'i18next';
import { heliosTheme } from '../theme';
import {getDayName} from './dateService';

const xAxisStyle = {
  axis: {
    stroke: 'rgba(255,255,255,0.9)',
  },
  tickLabels: {
    display: 'none',
  },
};

const xAxisWithTicksStyle = {
  axis: {
    stroke: 'rgba(255,255,255,0.9)',
  },
  tickLabels: {
    fill: 'rgba(255,255,255,1.0)',
    fontSize: 16,
    fontFamily: heliosTheme.typography.fontFamily,
  },
};

const yAxisStyle = {
  tickLabels: {
    fill: 'rgba(255,255,255,1.0)',
    fontSize: 16,
    fontFamily: heliosTheme.typography.fontFamily,
  },
  axis: {
    stroke: 'rgba(255,255,255,0.2)',
  },
};

const getWeekTickFormatFunction =
  (t: TFunction, shortAbbr: boolean) => (tick: any) => {
    const today = new Date();
    const daysAgo = 6 - tick;
    // keeping this code in case we decide to show longer abbrs in the future
    const abbr = t(
      `general.dayNames.${getDayName(
        today.getDay() - daysAgo < 0
          ? today.getDay() + 7 - daysAgo
          : today.getDay() - daysAgo,
      )}.abbr`,
    );
    // only show the first letter
    if (shortAbbr) {
      return abbr[0];
    }
    return abbr;
  };

const getColor = (value: number) => {
  //value from 0 to 1
  if (value < 0) {
   value = 0; 
  } else if (value > 1) {
    value = 1;
  }
  const hue=((1-value)*120).toString(10);
  return ["hsl(",hue,",50%,50%)"].join("");
};

export {
  xAxisStyle,
  xAxisWithTicksStyle,
  yAxisStyle,
  getWeekTickFormatFunction,
  getColor,
};
