import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPlayerSessionDetail, getPlayerSessions, updatePlayerSession } from '../api/playerSessionAPI';

export interface PlayerSessionState {
  playerSessions: any[] | null;
  playerSession: any | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
  uploadStatus: 'idle' | 'loading' | 'failed';
  uploadError: any;
}

const initialState: PlayerSessionState = {
  playerSessions: null,
  playerSession: null,
  status: 'idle',
  error: null,
  uploadStatus: 'idle',
  uploadError: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
const getPlayerSessionsAsync = createAsyncThunk(
  'playerSession/getPlayerSessions',
  async (params: {
    profileId?: number,
  }, { rejectWithValue }) => {
    const {profileId} = params;

    try {
      const response: any = await getPlayerSessions({profileId});
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getPlayerSessionDetailAsync = createAsyncThunk(
  'playerSession/getPlayerSessionDetail',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await getPlayerSessionDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updatePlayerSessionAsync = createAsyncThunk(
  'playerSession/updatePlayerSession',
  async (params: {
    id: number,
    params: {
      timeZone?: string,
    },
  }, { rejectWithValue }) => {
    try {
      const response: any = await updatePlayerSession(params.id, params.params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const playerSessionSlice = createSlice({
  name: 'playerSession',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getPlayerSessionsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPlayerSessionsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.playerSessions = action.payload.playerSessions;
        state.error = null;
      })
      .addCase(getPlayerSessionsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.playerSessions = [];
        state.error = action.payload;
      })
      .addCase(getPlayerSessionDetailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPlayerSessionDetailAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.playerSession = action.payload;
        state.error = null;
      })
      .addCase(getPlayerSessionDetailAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.playerSession = null;
        state.error = action.payload;
      })
      .addCase(updatePlayerSessionAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePlayerSessionAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.playerSession = {
          ...state.playerSession,
          ...action.payload.playerSession};
        state.error = null;
      })
      .addCase(updatePlayerSessionAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default playerSessionSlice.reducer;

export {
  getPlayerSessionsAsync,
  getPlayerSessionDetailAsync,
  updatePlayerSessionAsync,
};