import { AxiosPromise } from 'axios';
import api from './api';

export function createCustomerPortalSession(params: {
  profileId: number,
  membershipLevel?: string,
}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .post(`/create-customer-portal-session`, params)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}