import { Box, Button, CircularProgress, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { resendShopifySubscriptionEmail } from '../../api/shopifySubscriptionAPI';

interface Props {}

const ResendShopifyMembershipKey: React.FC<Props> = (props) => {
  const {t} = useTranslation();

  const [shopifySubscriptionId, setShopifySubscriptionId] = useState<string>('');
  const [pending, setPending] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (!shopifySubscriptionId || !Number.isInteger(parseInt(shopifySubscriptionId))) {
      alert(t('admin.fetchMembershipProDestination.enterProfileId'));
    } else {
      setPending(true);
      try {
        const res = await resendShopifySubscriptionEmail({
          shopifySubscriptionId: parseInt(shopifySubscriptionId),
        });
        setPending(false);
        if (res?.data?.data?.resendShopifySubscriptionEmail?.success) {
          window.alert(t('admin.resendShopifyMembershipKey.success', {shopifySubscriptionId: shopifySubscriptionId}));
        } else {
          window.alert(t('admin.resendShopifyMembershipKey.error', {error: res?.data?.data?.resendShopifySubscriptionEmail?.error}));
        }
      } catch (err: any) {
        setPending(false);
        window.alert(t('admin.resendShopifyMembershipKey.error', {error: err}));
      }
    }
  }

  return (
    <Paper sx={{p: 5}}>
      <Typography variant="body1" sx={{mb: 3}}>
        {t('admin.resendShopifyMembershipKey.info')}
      </Typography>
      <TextField
        label={t('admin.shopifySubscriptionId')}
        sx={{width: '100%', mb: 3}}
        onChange={(e) => setShopifySubscriptionId(e.target.value)}
        value={shopifySubscriptionId}
      />
      <Box sx={{textAlign: 'right'}}>
        <Button
          variant='contained'
          disabled={!Number.isInteger(parseInt(shopifySubscriptionId)) || pending}
          onClick={handleSubmit}>
            Submit
            {pending && (
              <CircularProgress
                sx={{color: '#fff', ml: 1}}
                size={16}
              />
            )}
        </Button>
      </Box>
    </Paper>
  );
};

export default ResendShopifyMembershipKey;
