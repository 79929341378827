import {AxiosPromise} from 'axios';
import api from './api';
import apiGraphQL from './apiGraphQL';

export function getPlayerSessions(params: {profileId?: number}) {
  const {profileId} = params;

  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get(`/playersessions${profileId ? `?profileId=${profileId}` : ''}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getPlayerSessionDetail(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get(`/playersession/${id}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function updatePlayerSession(id: number, params: {timeZone?: string}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .put(`/playersessions/${id}`, params)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function reprocessPlayerSession({id}: {id: number}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      apiGraphQL
        .post('/graphql', {
          query: `mutation ($id: Int!) {
            reprocessPlayerSession(id: $id) {
              success,
            }
          }`,
          variables: {
            id,
          },
        })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function createLostPlayerSession({
  profileId,
  teamSessionId,
}: {
  profileId: number;
  teamSessionId?: number;
}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      apiGraphQL
        .post('/graphql', {
          query: `mutation CreateLostPlayerSession($input: CreateLostPlayerSessionInput!) {
            createLostPlayerSession(input: $input) {
              id
            }
          }`,
          variables: {
            input: {
              profileId,
              teamSessionId,
            },
          },
        })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}
