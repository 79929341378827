import { AxiosPromise } from 'axios';
import apiGraphQL from "./apiGraphQL";

export function claimShopifySubscription({
  profileId,
  code,
}: {
  profileId: number;
  code: string;
}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      apiGraphQL
        .post('/graphql', {
          query: `mutation ($profileId: Int!, $code: String) {
            claimShopifySubscription(profileId: $profileId, code: $code) {
              error
              success
            }
          }`,
          variables: {
            profileId,
            code,
          },
        })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function resendShopifySubscriptionEmail({
  shopifySubscriptionId,
}: {
  shopifySubscriptionId: number;
}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      apiGraphQL
        .post('/graphql', {
          query: `mutation ($shopifySubscriptionId: Int!) {
            resendShopifySubscriptionEmail(shopifySubscriptionId: $shopifySubscriptionId) {
              error
              success
            }
          }`,
          variables: {
            shopifySubscriptionId,
          },
        })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}