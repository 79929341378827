import { Button, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

import ProfileIdentity from './ProfileIdentity';

interface Props {
  firstName: string;
  lastName: string;
  position?: string;
  profilePictureUrl?: string;
  buttons?: {
    text: string;
    navigationUrl?: string;
    onClick?: () => void;
  }[]
}



const PlayerListItem: React.FC<Props> = ({
  firstName,
  lastName,
  position,
  profilePictureUrl,
  buttons,
}) => {
  return (
    <TableRow>
      <TableCell>
        <ProfileIdentity
          firstName={firstName}
          lastName={lastName}
          position={position}
          profilePictureUrl={profilePictureUrl}
        />
      </TableCell>
      <TableCell align="right">
        {(buttons ?? []).map((button) => {
          if (button.navigationUrl) {
            return (
              <Button key={button.text} component={NavLink} to={button.navigationUrl}>
                {button.text}
              </Button>
            );
          } else if (button.onClick) {
            return (
              <Button key={button.text} onClick={button.onClick}>
                {button.text}
              </Button>
            );
          }
          return null;
        })}
      </TableCell>
    </TableRow>
  )
};

export default PlayerListItem;
