import React from 'react';
import {IconButton} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

interface Props {
  paused: boolean;
  togglePlay: () => void;
}

const PlayPause: React.FC<Props> = ({paused, togglePlay}) => (
  <IconButton onClick={togglePlay}>
    {paused ? <PlayArrowIcon /> : <PauseIcon />}
  </IconButton>
);

export default PlayPause;
