import {
  ButtonBase,
  Card,
  CardContent,
  Grid,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import {DateTime} from 'luxon';
import React from 'react';
import {getEnergyRatio} from '../../services/measurementService';
import {getTime} from '../../services/dateService';
import SessionTileStat from '../shared/SessionTileStat';
import {isNullOrUndefined} from '../../helpers';

interface Props {
  sx: SxProps;
  active: boolean;
  shiftNumber: number;
  startTime?: string;
  activeTime?: number;
  strintTotalTime?: number;
  strideCountTotal?: number;
  onClick: () => void;
}

const PlayerSessionShiftCard: React.FC<Props> = ({
  sx,
  active,
  shiftNumber,
  startTime,
  activeTime,
  strintTotalTime,
  strideCountTotal,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <Card
      sx={sx}
      style={
        active
          ? {
              border: 'solid',
              borderColor: theme.palette.primary.main,
              borderWidth: 2,
            }
          : undefined
      }>
      <ButtonBase sx={{width: '100%'}} onClick={onClick}>
        <CardContent sx={{width: '100%'}}>
          <Typography
            variant="h5"
            sx={{
              textAlign: 'left',
              mb: 1,
            }}>{`Shift ${shiftNumber}${
            startTime
              ? ` - ${DateTime.fromISO(startTime).toFormat('h:mm a')}`
              : ''
          }`}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <SessionTileStat label="Time" value={getTime(activeTime)} />
            </Grid>
            <Grid item xs={4}>
              {!isNullOrUndefined(strintTotalTime) &&
                !isNullOrUndefined(activeTime) && (
                  <SessionTileStat
                    label="ER"
                    value={
                      getEnergyRatio({
                        strideTime: strintTotalTime as number,
                        totalTime: activeTime as number,
                      }) ?? '--'
                    }
                  />
                )}
            </Grid>
            <Grid item xs={4}>
              <SessionTileStat label="SC" value={strideCountTotal ?? '--'} />
            </Grid>
          </Grid>
        </CardContent>
      </ButtonBase>
    </Card>
  );
};

export default React.memo(PlayerSessionShiftCard);
