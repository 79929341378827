import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDevices } from '../api/deviceAPI';

export interface PlayerSessionState {
  devices: any[] | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: PlayerSessionState = {
  devices: null,
  status: 'idle',
  error: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
const getDevicesAsync = createAsyncThunk(
  'device/getPlayerSessions',
  async (params: {
    profileId?: number,
  }, { rejectWithValue }) => {
    const {profileId} = params;

    try {
      const response: any = await getDevices({profileId});
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getDevicesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getDevicesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.devices = action.payload.devices;
        state.error = null;
      })
      .addCase(getDevicesAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.devices = [];
        state.error = action.payload;
      })
  },
});

export default deviceSlice.reducer;

export {
  getDevicesAsync,
};