export const getSpeedMultiplier = (isMetric: boolean = false) => {
  return isMetric ? 3.6 : 2.23694;
};

export const getSpeedUnit = (isMetric: boolean = false) => {
  return isMetric ? 'kph' : 'mph';
};

export const getEnergyRatio = ({
  strideTime,
  totalTime,
  hidePercentage,
}: {
  strideTime: number;
  totalTime: number;
  hidePercentage?: boolean;
}) => {
  if (totalTime <= 0) {
    return null;
  }

  let ratio = strideTime / totalTime;

  if (ratio > 1) {
    ratio = 1;
  } else if (ratio < 0) {
    ratio = 0;
  }

  if (hidePercentage) {
    return `${Math.round(ratio * 100)}`;
  }
  return `${Math.round(ratio * 100)}%`;
};
