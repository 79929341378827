import React, {useState} from 'react';
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {updateProfileAsync} from '../store/profileSlice';
import styles from '../styles';

enum MeasurementUnits {
  imperial = 'imperial',
  metric = 'metric',
}

interface Props {}

const Settings: React.FC<Props> = props => {
  const {profile} = useAppSelector(state => state.profile);
  const dispatch = useAppDispatch();
  const [unitValue, setUnitValue] = useState<MeasurementUnits>(
    profile.preferredUnits || MeasurementUnits.imperial,
  );
  const [submitting, setSubmitting] = useState(false);
  const handleUnitChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSubmitting(true);
    setUnitValue(event.target.value as MeasurementUnits);
    await dispatch(
      updateProfileAsync({
        profileId: profile.id,
        profileData: {preferredUnits: event.target.value as MeasurementUnits},
      }),
    );
    setSubmitting(false);
  };

  return (
    <Container>
      <Typography variant="h1">Settings</Typography>
      <Box sx={[styles.marginTop]}>
        <FormControl disabled={submitting}>
          <FormLabel id="units-radio-buttons-group-label">
            Preferred Measurement Units
          </FormLabel>
          <RadioGroup
            aria-labelledby="units-radio-buttons-group-label"
            defaultValue={unitValue}
            value={unitValue}
            name="units-radio-buttons-group"
            onChange={handleUnitChange}>
            <FormControlLabel
              value={MeasurementUnits.imperial}
              control={<Radio />}
              label="Imperial"
            />
            <FormControlLabel
              value={MeasurementUnits.metric}
              control={<Radio />}
              label="Metric"
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </Container>
  );
};

export default Settings;
