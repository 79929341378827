import {Box, Slider, Typography} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {getTime} from '../../../../services/dateService';

interface Props {
  videoRef: React.RefObject<HTMLVideoElement>;
  playerControls: any;
}

const Seekbar: React.FC<Props> = ({videoRef, playerControls}) => {
  const [seeking, setSeeking] = useState(false);
  const [currentTime, setCurrentTime] = React.useState(
    videoRef.current?.currentTime || 0,
  );

  const calculateTime = useCallback(() => {
    if (videoRef.current?.duration) {
      const time = (videoRef.current.duration || 0) - currentTime;
      return `-${getTime(time)}`;
    }
    return getTime(0);
  }, [currentTime, videoRef]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (videoRef.current) {
        if (!seeking) {
          setCurrentTime(videoRef.current.currentTime);
        }
      }
    }, 50);
    return () => clearInterval(interval);
  }, [seeking, videoRef]);

  return (
    <Box sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
      <Slider
        min={0}
        max={videoRef.current?.duration || 0}
        value={currentTime}
        sx={{marginLeft: 1, marginRight: 2}}
        onChange={(_, value) => {
          if (videoRef.current && typeof value === 'number') {
            setCurrentTime(value);
            if (!seeking) {
              videoRef.current.currentTime = value;
            }
          }
        }}
        onMouseDown={() => {
          setSeeking(true);
          if (!playerControls.paused) {
            videoRef.current?.pause();
          }
        }}
        onMouseUp={() => {
          setSeeking(false);
          if (videoRef.current) {
            videoRef.current.currentTime = currentTime;
            if (!playerControls.paused) {
              videoRef.current?.play();
            }
          }
        }}
      />
      <Typography>{calculateTime()}</Typography>
    </Box>
  );
};

export default Seekbar;
