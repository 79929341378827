import React from "react";
import { Box, Grid, styled } from "@mui/material";
import SessionTileStat from "./SessionTileStat";

const StatWrapper = styled(Box)(() => ({
  textAlign: 'center',
}));

interface PlayerSessionShiftStatProps {
  label: string;
  value: string;
  unit?: string;
  valid: boolean;
}

const PlayerSessionShiftStat: React.FC<PlayerSessionShiftStatProps> = ({
  label,
  value,
  unit,
  valid,
}) => {
  return (
    <Grid item xs={6} md={3} sx={{opacity: valid ? 1 : .25}}>
      <StatWrapper>
        <SessionTileStat
          label={label}
          value={value}
          unit={unit}
        />
      </StatWrapper>
    </Grid>
  )
};

export default PlayerSessionShiftStat;
