import {Box, Button, Modal, Typography} from '@mui/material';
import React, {useState} from 'react';
import styles from '../../../styles';
import VideoSessionEventAnchor from './VideoSessionEventAnchor';
import {updateSessionVideoSegment} from '../../../api/sessionVideoAPI';
import {useTranslation} from 'react-i18next';
import {SessionEvent} from '../../../interfaces';

interface Props {
  showModal: boolean;
  setShowModal: (showModal: number | null) => void;
  previewUrl: string;
  sessionEvents: SessionEvent[];
  sessionVideoSegmentId: number;
  processVideo: () => void;
  refresh: () => void;
}

const Mp4AnchorSelection: React.FC<Props> = ({
  showModal,
  setShowModal,
  previewUrl,
  sessionEvents,
  sessionVideoSegmentId,
  processVideo,
  refresh,
}) => {
  const {t} = useTranslation();

  const [videoStartTime, setVideoStartTime] = useState<Date | null>(null);
  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(null);
      }}>
      <Box sx={styles.modal}>
        <Typography variant="h4" sx={{mb: 2}}>
          {t(`video.mp4AnchorSelection.title`)}
        </Typography>
        <ul>
          <li>{t('video.mp4AnchorSelection.instructions')}</li>
        </ul>
        <VideoSessionEventAnchor
          videoUrl={previewUrl}
          sessionEvents={sessionEvents ?? []}
          setVideoStartTime={setVideoStartTime}
        />
        <Box sx={{textAlign: 'right'}}>
          <Button
            variant="contained"
            disabled={!videoStartTime}
            onClick={async () => {
              await updateSessionVideoSegment({
                input: {
                  id: sessionVideoSegmentId,
                  userSelectedStartTime: videoStartTime ?? undefined,
                },
              });
              await processVideo();
              await refresh();
              setShowModal(null);
            }}>
            {t('general.submit')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default React.memo(Mp4AnchorSelection);
