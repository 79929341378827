import {Container} from '@mui/material';
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {getTeamSessionsAsync} from '../../store/teamSessionSlice';
import PageTitle from '../shared/PageTitle';
import TeamSessionsList from '../shared/TeamSessionsList';

interface Props {}

const TeamSessions: React.FC<Props> = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const currentState = useAppSelector(state => ({
    teamSession: state.teamSession,
    team: state.team,
  }));
  const {teamId} = useParams();
  const teamIdInt = parseInt(teamId ?? '');

  useEffect(() => {
    dispatch(getTeamSessionsAsync(teamIdInt));
  }, [teamIdInt]);

  const activeTeam = (currentState.team?.teams ?? []).filter(
    team => team.id === teamIdInt,
  )[0];

  return (
    <>
      <Helmet>
        <title>
          {t('navigation.pageTitle', {pageName: t('pages.teamSessions')})}
        </title>
      </Helmet>
      <Container>
        <PageTitle>{t('pages.teamSessions')}</PageTitle>
        {/* hide back button until we create a coach dashboard screen */}
        {/* <BackButton navigationUrl="/">
          {t('navigation.back', {pageName: t('pages.dashboard')})}
        </BackButton> */}
        <TeamSessionsList
          teamSessions={[...(currentState.teamSession?.teamSessions ?? [])]}
          teamSize={(activeTeam?.players ?? []).length}
        />
      </Container>
    </>
  );
};

export default TeamSessions;
