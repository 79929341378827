import {Box, IconButton, Slider} from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import React, {useMemo, useState} from 'react';

interface Props {
  videoRef: React.RefObject<HTMLVideoElement>;
  setPreviousVolume: (val: number) => void;
  previousVolume: number;
}

const Volume: React.FC<Props> = ({
  videoRef,
  setPreviousVolume,
  previousVolume,
}) => {
  const [muted, setMuted] = useState(videoRef.current?.muted || false);
  const [currentVolume, setCurrentVolume] = React.useState(
    (videoRef.current?.volume || 0) * 100,
  );

  const toggleMute = () => {
    if (videoRef.current) {
      if (muted) {
        videoRef.current.volume = previousVolume;
        setCurrentVolume(previousVolume * 100);
      } else {
        videoRef.current.volume = 0;
        setCurrentVolume(0);
      }
      setMuted(!muted);
      videoRef.current.muted = !videoRef.current.muted;
    }
  };

  const volumeBarMute = (muted: boolean) => {
    if (videoRef.current) {
      setMuted(muted);
      videoRef.current.muted = muted;
    }
  };

  const volumeIcon = useMemo(() => {
    return muted ? <VolumeOffIcon /> : <VolumeUpIcon />;
  }, [muted]);

  return (
    <Box sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
      <IconButton onClick={toggleMute}>{volumeIcon}</IconButton>
      <Slider
        min={0}
        max={100}
        value={currentVolume}
        sx={{marginLeft: 1, marginRight: 2}}
        onChange={(_, value) => {
          if (videoRef.current && typeof value === 'number') {
            setCurrentVolume(value);
            videoRef.current.volume = value / 100;
            setPreviousVolume(value / 100);
            if (value === 0) {
              volumeBarMute(true);
            } else {
              volumeBarMute(false);
            }
          }
        }}
      />
    </Box>
  );
};

export default Volume;
