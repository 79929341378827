import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getCurrentProfile,
  getProfileDetail,
  getProfileMembership,
  updateProfile,
  updateProfileMembership
} from '../api/profileAPI';

export interface ProfileState {
  profile: any | null;
  profileDetail: any | null;
  membership: any | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: ProfileState = {
  profile: null,
  profileDetail: null,
  membership: null,
  status: 'idle',
  error: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
const getCurrentProfileAsync = createAsyncThunk(
  'profile/getCurrentProfile',
  async (profileData, { rejectWithValue }) => {
    try {
      const response: any = await getCurrentProfile();
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getProfileDetailAsync = createAsyncThunk(
  'profile/getProfileDetail',
  async ({profileId}: {
    profileId: number;
  }, { rejectWithValue }) => {
    try {
      const response: any = await getProfileDetail({profileId});
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updateProfileMembershipAsync = createAsyncThunk(
  'profile/updateProfileMembership',
  async (params: {
    profileId?: number,
    membershipLevel: string,
  }, { rejectWithValue }) => {
    try {
      const response: any = await updateProfileMembership(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
        data: err.response.data
      });
    }
  },
);

const getProfileMembershipAsync = createAsyncThunk(
  'profile/getProfileMembership',
  async ({profileId}: {
    profileId?: number,
  }, { rejectWithValue }) => {
    try {
      const response: any = await getProfileMembership({profileId});
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
        data: err.response.data
      });
    }
  },
);

const updateProfileAsync = createAsyncThunk(
  'profile/updateProfile',
  async (
    params: {
      profileId?: number;
      profileData: any;
    },
    {rejectWithValue},
  ) => {
    try {
      const response: any = await updateProfile(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
        data: err.response.data,
      });
    }
  },
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentProfileAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCurrentProfileAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.profile = action.payload;
        state.error = null;
      })
      .addCase(getCurrentProfileAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.profile = null;
        state.error = action.payload;
      })
      .addCase(getProfileDetailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProfileDetailAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.profileDetail = action.payload;
        state.error = null;
      })
      .addCase(getProfileDetailAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.profileDetail = null;
        state.error = action.payload;
      })
      .addCase(getProfileMembershipAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProfileMembershipAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.membership = action.payload.membership;
        state.error = null;
      })
      .addCase(getProfileMembershipAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.membership = null;
        state.error = action.payload;
      })
      .addCase(updateProfileMembershipAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateProfileMembershipAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updateProfileMembershipAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateProfileAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateProfileAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updateProfileAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default profileSlice.reducer;

export {
  getCurrentProfileAsync,
  getProfileDetailAsync,
  updateProfileMembershipAsync,
  getProfileMembershipAsync,
  updateProfileAsync,
};
