import React from 'react';
import {IconButton} from '@mui/material';
import EjectIcon from '@mui/icons-material/Eject';

interface Props {
  backward?: boolean;
  onClick: () => void;
  playbackRate: number;
  paused: boolean;
  reversing?: boolean;
}

const SlowMotion: React.FC<Props> = ({
  backward,
  onClick,
  playbackRate,
  paused,
  reversing,
}) => {
  const activeRate = 0.4;
  const currentColorForward =
    playbackRate === activeRate && !paused ? 'primary' : 'inherit';
  const currentColorBackward = reversing ? 'primary' : 'inherit';
  return (
    <IconButton onClick={onClick}>
      <EjectIcon
        color={backward ? currentColorBackward : currentColorForward}
        sx={{transform: backward ? 'rotate(270deg)' : 'rotate(90deg)'}}
      />
    </IconButton>
  );
};

export default SlowMotion;

SlowMotion.defaultProps = {
  backward: false,
  reversing: false,
};
