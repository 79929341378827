import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, {useEffect, useRef} from 'react';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {useAppDispatch} from '../../store/hooks';
import {createSessionVideoAsync} from '../../store/sessionVideoSlice';
import {getPlayerSessionDetailAsync} from '../../store/playerSessionSlice';
import {getTeamSessionDetailAsync} from '../../store/teamSessionSlice';
import VideoPlayer from './VideoPlayer/VideoPlayer';
import {SessionVideo} from '../../interfaces';
import {DateTime} from 'luxon';

interface Props {
  sessionVideos: SessionVideo[];
  sessionType: 'player' | 'team';
  teamId?: number;
  profileId?: number;
  sessionId: number;
}

const SessionVideoManager: React.FC<Props> = ({
  sessionVideos,
  sessionType,
  teamId,
  profileId,
  sessionId,
}) => {
  const dispatch = useAppDispatch();
  const {hash} = useLocation();
  const navigate = useNavigate();

  const videoRef = useRef<HTMLVideoElement>(null);
  const allSessionVideos = (sessionVideos || [])
    .flatMap(sv => sv.sessionVideoSegments)
    .sort((a, b) => {
      if (
        (a?.userSelectedStartTime || a?.startTime) &&
        (b?.userSelectedStartTime || b?.startTime)
      ) {
        return (
          DateTime.fromISO((a.userSelectedStartTime ?? a.startTime) as string)
            .toJSDate()
            .getTime() -
          DateTime.fromISO((b.userSelectedStartTime ?? b.startTime) as string)
            .toJSDate()
            .getTime()
        );
      }

      // default return
      return 0;
    });
  const [videoIndex, setVideoIndex] = React.useState(0);

  const createSessionVideo = async () => {
    if (sessionType === 'player') {
      const createRes = await dispatch(
        createSessionVideoAsync({
          playerSessionId: sessionId,
        }),
      );
      const sessionVideoId = createRes?.payload?.sessionVideo?.id;
      if (sessionVideoId) {
        navigate(
          `/playersessions/${sessionId}/sessionvideos/${sessionVideoId}#add-video`,
        );
      }
      dispatch(getPlayerSessionDetailAsync(sessionId));
    } else if (sessionType === 'team') {
      const createRes = await dispatch(
        createSessionVideoAsync({
          teamSessionId: sessionId,
        }),
      );
      const sessionVideoId = createRes?.payload?.sessionVideo?.id;
      if (sessionVideoId) {
        navigate(
          `/teams/${teamId}/teamsessions/${sessionId}/sessionvideos/${sessionVideoId}#add-video`,
        );
      }
      await dispatch(getTeamSessionDetailAsync(sessionId));
    }
  };

  useEffect(() => {
    if (!sessionVideos?.length && hash === '#add-video') {
      createSessionVideo();
    }
  }, [hash]);

  return (
    <Box sx={{mb: 2}}>
      {(sessionVideos || []).length > 0 ? (
        <TableContainer sx={{mb: 1}} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Processed</TableCell>
                {sessionType === 'player' && (
                  <TableCell align="right"></TableCell>
                )}
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(sessionVideos || []).map((row: SessionVideo) => (
                <TableRow key={row.id}>
                  <TableCell>
                    {(row.playerSessionVideoClips ?? []).length > 0 ? (
                      <CheckIcon />
                    ) : null}
                  </TableCell>
                  {sessionType === 'player' && (
                    <TableCell align="right">
                      {row.clipsUrl ? (
                        <Button href={row.clipsUrl} download>
                          Download Clips
                        </Button>
                      ) : null}
                    </TableCell>
                  )}
                  <TableCell align="right">
                    <Button
                      component={NavLink}
                      to={
                        sessionType === 'team'
                          ? `/teams/${teamId}/teamsessions/${sessionId}/sessionvideos/${row.id}`
                          : teamId
                            ? `/teams/${teamId}/players/${profileId}/playersessions/${sessionId}/sessionvideos/${row.id}`
                            : profileId
                              ? `/family/children/${profileId}/playersessions/${sessionId}/sessionvideos/${row.id}`
                              : `/playersessions/${sessionId}/sessionvideos/${row.id}`
                      }>
                      Add Session Videos
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {sessionType === 'team' && allSessionVideos.length > 0 && (
            <Box>
              {allSessionVideos.length > 1 && (
                <Select
                  sx={{
                    width: '100%',
                    fontWeight: 700,
                    fontSize: '1.2rem',
                    fontStyle: 'italic',
                  }}
                  labelId="team-select-label"
                  value={videoIndex}
                  onChange={v => {
                    setVideoIndex(v.target.value as number);
                    videoRef.current?.load();
                  }}>
                  {allSessionVideos?.map((sv, index) => (
                    <MenuItem key={sv?.id} value={index}>
                      {sv?.userSelectedStartTime || sv?.startTime
                        ? DateTime.fromISO(
                            (sv?.userSelectedStartTime ??
                              sv?.startTime) as string,
                          ).toFormat('M/d/yyyy h:mm a')
                        : sv?.fileName}
                    </MenuItem>
                  ))}
                </Select>
              )}
              {allSessionVideos[videoIndex]?.videoUrl && (
                <VideoPlayer
                  videoRef={videoRef}
                  sourceUrl={allSessionVideos[videoIndex]?.videoUrl as string}
                  shiftClips={[]}
                  videoIndex={videoIndex}
                  setVideoIndex={setVideoIndex}
                />
              )}
            </Box>
          )}
        </TableContainer>
      ) : null}
      {(sessionVideos || []).length === 0 && (
        <Button variant="contained" onClick={createSessionVideo}>
          Create Session Video
        </Button>
      )}
    </Box>
  );
};

export default SessionVideoManager;
