import {Container, styled} from '@mui/material';
import {DateTime} from 'luxon';
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import BackButton from '../shared/BackButton';
import FullScreenLoadingSpinner from '../shared/FullScreenLoading';
import PageSubtitle from '../shared/PageSubtitle';
import PageTitle from '../shared/PageTitle';
import {getTeamSessionGroupDetailAsync} from '../../store/teamSessionGroupSlice';
import TeamSessionsList from '../shared/TeamSessionsList';
import TeamShiftUtilization from '../shared/TeamShiftUtilization';
import PracticeEfficiency from '../shared/PracticeEfficiency/PracticeEfficiency';

interface Props {}

const StyledSessionType = styled('span')(() => ({
  display: 'block',
  textTransform: 'capitalize',
}));

const TeamSessionGroupDetail: React.FC<Props> = () => {
  const {t} = useTranslation();
  const currentState = useAppSelector(state => ({
    profile: state.profile,
    team: state.team,
    teamSessionGroup: state.teamSessionGroup,
  }));
  const dispatch = useAppDispatch();
  const {teamId, teamSessionId} = useParams();
  const teamSessionIdInt = parseInt(teamSessionId ?? '');
  const teamIdInt = parseInt(teamId ?? '');

  useEffect(() => {
    dispatch(getTeamSessionGroupDetailAsync(teamSessionIdInt));
  }, [dispatch, teamSessionIdInt]);

  const activeTeam = (currentState.team?.teams ?? []).filter(
    team => team.id === teamIdInt,
  )[0];

  const title = t('navigation.pageTitle', {
    pageName: t('pages.teamSessionDetail'),
  });
  const sessionType =
    currentState.teamSessionGroup?.teamSessionGroup?.teamSessions[0]?.type;

  if (
    currentState.teamSessionGroup.status === 'loading' ||
    !currentState.teamSessionGroup?.teamSessionGroup
  ) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <FullScreenLoadingSpinner />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <PageTitle>{t('pages.teamSessionGroupDetail')}</PageTitle>
        <BackButton navigationUrl={`/teams/${teamId}/teamsessions`}>
          {t('navigation.back', {pageName: t('pages.teamSessions')})}
        </BackButton>
        <PageSubtitle>
          <StyledSessionType>{sessionType}</StyledSessionType>
          {DateTime.fromISO(
            currentState.teamSessionGroup?.teamSessionGroup?.teamSessions[0]
              ?.startTime,
          ).toFormat('EEEE M/d/yyyy h:mm a')}
        </PageSubtitle>
        <TeamShiftUtilization
          teamSessions={
            currentState.teamSessionGroup?.teamSessionGroup?.teamSessions ?? []
          }
        />
        {sessionType !== 'game' && (
          <PracticeEfficiency
            playerSessions={
              currentState.teamSessionGroup?.teamSessionGroup?.teamSessions
                ?.playerSessions || []
            }
            teamSessionStartTime={
              currentState.teamSessionGroup?.teamSessionGroup?.teamSessions[0]
                ?.startTime
            }
            teamSessionEndTime={
              currentState.teamSessionGroup?.teamSessionGroup?.teamSessions[0]
                ?.endTime
            }
            practiceEfficiencyMax={activeTeam?.practiceEfficiencyMax}
          />
        )}
        <TeamSessionsList
          teamSessions={
            currentState.teamSessionGroup?.teamSessionGroup?.teamSessions ?? []
          }
          teamSize={(activeTeam?.players ?? []).length}
          isTeamSessionGroup={true}
        />
      </Container>
    </>
  );
};

export default TeamSessionGroupDetail;
