import React from 'react';
import {IconButton} from '@mui/material';
import Forward5Icon from '@mui/icons-material/Forward5';
import Replay5Icon from '@mui/icons-material/Replay5';

interface Props {
  backward?: boolean;
  onClick: () => void;
}

const Skip: React.FC<Props> = ({backward, onClick}) => (
  <IconButton onClick={onClick}>
    {backward ? <Replay5Icon /> : <Forward5Icon />}
  </IconButton>
);

export default Skip;

Skip.defaultProps = {
  backward: false,
};
