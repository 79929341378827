import {Alert} from '@mui/material';
import React, {useEffect, useState} from 'react';
import SelectVideoFiles from './SelectVideoFiles';
import VideoTypeHeader from './VideoTypeHeader';
import {VideoTypes} from '../../../enums';
import {useTranslation} from 'react-i18next';
import AddVideoWrapper from './AddVideoWrapper';
import {mp4Regex} from '../../../services/videoService';

interface Props {
  setShowModal: (showModal: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  createSegment: (params: {
    fileName?: string;
    parts?: number;
    veoUrl?: string;
  }) => unknown;
  createSegmentComplete: (params: {
    uploadId: string;
    key: string;
    parts: object[];
  }) => void;
  processVideo: () => void;
  refresh: () => void;
  sessionEvents: object[];
}

const AddMp4Video: React.FC<Props> = ({
  setShowModal,
  loading,
  setLoading,
  createSegment,
  createSegmentComplete,
  processVideo,
  refresh,
  sessionEvents,
}) => {
  const {t} = useTranslation();
  const [files, setFiles] = useState<FileList | null>(null);
  const [isValidFilenames, setIsValidFilenames] = useState<boolean | null>(
    null,
  );

  useEffect(() => {
    if (files) {
      const filenames = [...files].map(file => file.name);
      const validFilenames = filenames.filter(filename =>
        mp4Regex.exec(filename),
      );
      setIsValidFilenames(validFilenames.length === filenames.length);
    } else {
      setIsValidFilenames(null);
    }
  }, [files]);

  return (
    <AddVideoWrapper type={VideoTypes.mp4}>
      <VideoTypeHeader type={VideoTypes.mp4} />
      {sessionEvents.length > 0 ? (
        <>
          {files && !isValidFilenames && (
            <Alert severity="warning" variant="outlined" sx={{mb: 1}}>
              {t('video.videoTypes.mp4.invalidFilenameError')}
            </Alert>
          )}
          <SelectVideoFiles
            setShowModal={setShowModal}
            loading={loading}
            setLoading={setLoading}
            files={files}
            setFiles={setFiles}
            createSegment={createSegment}
            createSegmentComplete={createSegmentComplete}
            processVideo={processVideo}
            refresh={refresh}
            requiresSessionEventAlignment={true}
          />
        </>
      ) : (
        <Alert severity="info" variant="outlined" sx={{mb: 1}}>
          {t('video.videoTypes.mp4.noSessionEventsError')}
        </Alert>
      )}
    </AddVideoWrapper>
  );
};

export default AddMp4Video;
