import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getTeamSessionDetail, getTeamSessions, updateTeamSession } from '../api/teamSessionAPI';

export interface TeamSessionState {
  teamSessions: any[] | null;
  teamSession: any | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
  uploadStatus: 'idle' | 'loading' | 'failed';
  uploadError: any;
}

const initialState: TeamSessionState = {
  teamSessions: null,
  teamSession: null,
  status: 'idle',
  error: null,
  uploadStatus: 'idle',
  uploadError: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
const getTeamSessionsAsync = createAsyncThunk(
  'teamSession/getTeamSessions',
  async (teamId: number, { rejectWithValue }) => {
    try {
      const response: any = await getTeamSessions(teamId);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getTeamSessionDetailAsync = createAsyncThunk(
  'teamSession/getTeamSessionDetail',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await getTeamSessionDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updateTeamSessionAsync = createAsyncThunk(
  'teamSession/updateTeamSession',
  async (params: {
    id: number,
    params: {
      timeZone?: string,
    },
  }, { rejectWithValue }) => {
    try {
      const response: any = await updateTeamSession(params.id, params.params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const teamSessionSlice = createSlice({
  name: 'teamSession',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getTeamSessionsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTeamSessionsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.teamSessions = action.payload.teamSessions;
        state.error = null;
      })
      .addCase(getTeamSessionsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.teamSessions = [];
        state.error = action.payload;
      })
      .addCase(getTeamSessionDetailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTeamSessionDetailAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.teamSession = action.payload;
        state.error = null;
      })
      .addCase(getTeamSessionDetailAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.teamSession = null;
        state.error = action.payload;
      })
      .addCase(updateTeamSessionAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateTeamSessionAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.teamSession = {
          ...state.teamSession,
          ...action.payload.teamSession};
        state.error = null;
      })
      .addCase(updateTeamSessionAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default teamSessionSlice.reducer;

export {
  getTeamSessionsAsync,
  getTeamSessionDetailAsync,
  updateTeamSessionAsync,
};