import React from 'react';
import { AmplifyProvider, Authenticator, Theme } from '@aws-amplify/ui-react';
import Amplify, { Auth, I18n } from 'aws-amplify';
import { Translations } from "@aws-amplify/ui-components";
import '@aws-amplify/ui-react/styles.css';
import Color from 'color';
import HeliosLogo from './images/HELIOS_Logo_and_Wordmark_White_20220621.png';
import { Box, Link, Typography } from '@mui/material';
import Main from './components/Main';

const authScreenLabels = {
  en: {
      [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: 'Email Address',
  }
};

I18n.setLanguage('en');
I18n.putVocabularies(authScreenLabels);

Amplify.configure({
  Auth: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID, 
      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_COGNITO_REGION, 
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID
      userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID, 
  },
});

function App() {
  const services = {
    async handleSignIn(formData: any) {
      let { username, password } = formData;
      // force lowercase email username
      username = username.toLowerCase();
      return Auth.signIn({
        username,
        password,
      });
    },
  };

  const components = {
    Header() {  
      return (
        <Box sx={{mb: 2, textAlign: 'center'}}>
          <img src={HeliosLogo} alt="Helios Logo" width={150} />
        </Box>
      );
    },
    Footer() {
      return (
        <Box sx={{mt: 2, textAlign: 'center'}}>
          <Typography variant='body1' color={"#fff"}>
            Return to&nbsp;
            <Link color={"#fff"} href="https://www.helioshockey.com">www.helioshockey.com</Link>
          </Typography>
        </Box>
      )
    }
  }

  const theme: Theme = {
    name: 'Auth Theme',
    
    tokens: {
      components: {
        authenticator: {
          modal: {
            backgroundColor: {
              value: 'black',
            },
          },
        },
        button: {
          primary: {
            backgroundColor: {
              value: '#F26014',
            },
            _hover: {
              backgroundColor: {
                value: Color('#F26014').darken(.2).string(),
              },
            },
            _focus: {
              backgroundColor: {
                value: Color('#F26014').darken(.2).string(),
              },
            },
            _active: {
              backgroundColor: {
                value: Color('#F26014').darken(.2).string(),
              },
            }
          },
        },
      },
    },
  };

  return (
    <AmplifyProvider theme={theme}>
      <Authenticator
        components={components}
        services={services}
        hideSignUp={true}
        variation="modal"  
      >
        {({ signOut, user }) => (
          <Main user={user} />
        )}
      </Authenticator>
    </AmplifyProvider>
  );
}

export default App;
