import { AxiosPromise } from 'axios';
import apiGraphQL from './apiGraphQL';

export function getTeamActiveDeviceGroups(teamId: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      apiGraphQL
        .post('/graphql', {
          query: `query ($teamId: Int!) {
            getTeamActiveDeviceGroups(teamId: $teamId) {
              success
              groups {
                name
                count
              }
            }
          }`,
          variables: {
            teamId,
          },
        })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}
export function updateTeamActiveDeviceGroup(teamId: number, activeTeamDeviceGroup: string, ) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      apiGraphQL
        .post('/graphql', {
          query: `mutation ($teamId: Int!, $activeTeamDeviceGroup: String) {
            updateTeamActiveDeviceGroup(teamId: $teamId, activeTeamDeviceGroup: $activeTeamDeviceGroup) {
              success
            }
          }`,
          variables: {
            teamId,
            activeTeamDeviceGroup,
          },
        })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}
