const getMean = (array: number[]) => {
  const n = array.length;
  if (n === 0) {
    return null;
  }
  const mean = array.reduce((a, b) => a + b) / n;
  return mean;
}

const getStandardDeviation = (array: number[]) => {
  const n = array.length;
  const mean = getMean(array);
  if (n === 0 || mean === null) {
    return null;
  }
  return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
}

// const getMax = (array: number[]) => {
//   if (array.filter(x => x || x === 0).length === 0) {
//     return null;
//   }

//   const max = array.reduce(function(a, b) {
//     return Math.max(a, b);
//   }, null);

//   return max;
// }

const getHarmonicMean = (array: number[]) => {
  // Declare sum variables and initialize
  // with zero.
  let sum = 0;
  const n = array.length;

  for (let i = 0; i < n; i++)
    sum = sum + (1 / array[i]);

  return n/sum;
}

const getGeometricMean = (array: number[]) => {
  // declare product variable and
  // initialize it to 1.
  let product = 1;
  const n = array.length;

  // Compute the product of all the
  // elements in the array.
  for (let i = 0; i < n; i++)
      product = product * array[i];

  // compute geometric mean through
  // formula pow(product, 1/n) and
  // return the value to main function.
  let gm = Math.pow(product, 1 / n);
  return gm;
}

export {
  getMean,
  getStandardDeviation,
  // getMax,
  getHarmonicMean,
  getGeometricMean,
}