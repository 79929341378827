import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import {Uploader} from '../../../services/uploader';
import {useTranslation} from 'react-i18next';
import {FileUploadStatus} from '../../../interfaces';
import React, {useRef, useState} from 'react';

interface Props {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  files: FileList | null;
  setFiles: (files: FileList | null) => void;
  disableUpload?: boolean;
  createSegment: (params: {
    fileName?: string;
    parts?: number;
    veoUrl?: string;
  }) => any;
  createSegmentComplete: (params: {
    uploadId: string;
    key: string;
    parts: object[];
  }) => void;
  setShowModal: (showModal: boolean) => void;
  processVideo: () => void;
  refresh: () => void;
  requiresSessionEventAlignment?: boolean;
}

const SelectVideoFiles: React.FC<Props> = ({
  loading,
  setLoading,
  files,
  setFiles,
  disableUpload,
  createSegment,
  createSegmentComplete,
  setShowModal,
  processVideo,
  refresh,
  requiresSessionEventAlignment,
}) => {
  const {t} = useTranslation();

  const [fileUploadProgress, setFileUploadProgress] = useState<
    FileUploadStatus[]
  >([]);

  const fileUploadProgressRef = useRef(fileUploadProgress);
  fileUploadProgressRef.current = fileUploadProgress;

  const handlePercentCompletedUpdated = ({
    filename,
    percentComplete,
  }: FileUploadStatus) => {
    const newFileUploadProgress = [...fileUploadProgressRef.current];
    const index = newFileUploadProgress.findIndex(f => f.filename === filename);
    if (index >= 0) {
      newFileUploadProgress[index].percentComplete = percentComplete;
      if (
        JSON.stringify(fileUploadProgress) !==
        JSON.stringify(newFileUploadProgress)
      ) {
        setFileUploadProgress([...newFileUploadProgress]);
      }
    }
  };

  return (
    <Box>
      <TableContainer sx={{mt: 1, mb: 1}} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Filename</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...(files ?? [])]
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map(file => (
                <TableRow key={file.name}>
                  <TableCell component="th" scope="row">
                    <Typography>{file.name}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>
                      {fileUploadProgress.filter(f => f.filename === file.name)
                        .length === 1
                        ? fileUploadProgress.filter(
                            f => f.filename === file.name,
                          )[0].percentComplete
                        : 0}
                      %
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          mb: 1,
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
        <label>
          <Button
            disabled={loading}
            variant="outlined"
            component="span"
            sx={{mr: 1}}>
            {t('video.selectFiles')}
          </Button>
          <input
            style={{display: 'none'}}
            type="file"
            multiple
            accept="video/mp4,video/quicktime"
            onChange={e => {
              if (e.target?.files) {
                setFiles(e.target?.files);
              }
            }}
          />
        </label>
        <Button
          disabled={loading || disableUpload || (files ?? []).length === 0}
          variant="contained"
          onClick={async () => {
            setLoading(true);
            // initialize file upload progress
            const initFileUploadProgress = [...(files ?? [])].map(
              (file: File) => ({
                filename: file.name,
                percentComplete: 0,
              }),
            );
            setFileUploadProgress([...initFileUploadProgress]);
            await Promise.all(
              [...(files ?? [])].map(file => {
                return new Promise((resolve, reject) => {
                  // this must be bigger than or equal to 5MB,
                  // otherwise AWS will respond with:
                  // "Your proposed upload is smaller than the minimum allowed size"
                  const chunkSize = 1024 * 1024 * 5;
                  try {
                    createSegment({
                      fileName: file?.name,
                      parts: Math.ceil(file.size / chunkSize),
                    }).then(
                      (apiRes: {
                        payload: {
                          multiPartSignedUrlList: {
                            PartNumber: number;
                            signedUrl: string;
                          }[];
                          uploadId: string;
                          key: string;
                        };
                      }) => {
                        let percentage: number = 0;

                        const uploader = new Uploader({
                          file,
                          fileName: file?.name,
                          chunkSize,
                          parts: apiRes.payload.multiPartSignedUrlList,
                          uploadId: apiRes.payload.uploadId,
                          key: apiRes.payload.key,
                          threadsQuantity: 15,
                        });

                        uploader
                          .onProgress(data => {
                            // to avoid the same percentage to be logged twice
                            if (data.percentage !== percentage) {
                              percentage = data.percentage;
                              handlePercentCompletedUpdated({
                                filename: file?.name,
                                percentComplete: percentage,
                              });
                            }
                          })
                          .onError((error: unknown) => {
                            console.error(error);
                            Sentry.addBreadcrumb({
                              category: 'SelectVideoFiles.uploader.onError',
                              message: JSON.stringify(error),
                              level: 'error',
                            });
                            Sentry.captureMessage(
                              'SelectVideoFiles.uploader.onError',
                              'error',
                            );
                            reject(error);
                          })
                          .onComplete(async data => {
                            await createSegmentComplete({
                              uploadId: apiRes.payload.uploadId,
                              key: apiRes.payload.key,
                              parts: data.parts,
                            });
                            resolve(true);
                          });

                        uploader.start();
                      },
                    );
                  } catch (err) {
                    console.error({err});
                    Sentry.captureException(err);
                    reject(err);
                  }
                });
              }),
            );
            setTimeout(async () => {
              setFiles(null);
              setFileUploadProgress([]);
              setShowModal(false);
              await processVideo();
              setLoading(false);
              if (requiresSessionEventAlignment) {
                window.alert(t('video.uploadCompleteMessageMp4')!);
              } else {
                window.alert(t('video.uploadCompleteMessage')!);
              }
              await refresh();
            }, 5000);
          }}>
          {t('general.upload')}
          {loading ? (
            <CircularProgress sx={{color: '#fff', ml: 1}} size={16} />
          ) : null}
        </Button>
      </Box>
    </Box>
  );
};

export default SelectVideoFiles;
