import { Box, Button, CircularProgress, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createCustomerPortalSession } from '../../api/membershipAPI';

interface Props {}

const FetchMembershipProDestination: React.FC<Props> = (props) => {
  const {t} = useTranslation();

  const [profileId, setProfileId] = useState<string>('');
  const [pending, setPending] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (!profileId || !Number.isInteger(parseInt(profileId))) {
      alert(t('admin.fetchMembershipProDestination.enterProfileId'));
    } else {
      setPending(true);
      try {
        const res = await createCustomerPortalSession({
          profileId: parseInt(profileId),
          membershipLevel: 'pro_month',
        });
  
        setPending(false);
        if (res?.data?.url) {
          window.open(res?.data?.url, '_blank');
        } else {
          setPending(false);
          window.alert(t('admin.fetchMembershipProDestination.errorCreatingPortalSession'));
        }
      } catch (err) {
        setPending(false);
        window.alert(t('admin.fetchMembershipProDestination.unableToAccessProfile'));
      }
    }
  }

  return (
    <Paper sx={{p: 5}}>
      <Typography variant="body1" sx={{mb: 3}}>
        {t('admin.fetchMembershipProDestination.info')}
      </Typography>
      <TextField
        label={t('admin.profileId')}
        sx={{width: '100%', mb: 3}}
        onChange={(e) => setProfileId(e.target.value)}
        value={profileId}
      />
      <Box sx={{textAlign: 'right'}}>
        <Button
          variant='contained'
          disabled={!Number.isInteger(parseInt(profileId)) || pending}
          onClick={handleSubmit}>
            Submit
            {pending && (
              <CircularProgress
                sx={{color: '#fff', ml: 1}}
                size={16}
              />
            )}
        </Button>
      </Box>
    </Paper>
  );
};

export default FetchMembershipProDestination;
