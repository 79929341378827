export const getPlayerProfileName = ({
  team,
  profileId,
}: {
  team: any;
  profileId: number;
}) => {
  const matchingPlayers = (team?.players ?? [])
    .filter((player: any) => player.profile?.id === profileId);

  // if current user if mathcing players is empty
  if (matchingPlayers.length === 0) {
    return '';
  }

  return `${matchingPlayers[0]?.profile?.firstName} ${matchingPlayers[0]?.profile?.lastName}`;
};

export const getPlayerProfile = ({
  team,
  profileId,
}: {
  team: any;
  profileId: number;
}) => {
  const matchingPlayers = (team?.players ?? [])
    .filter((player: any) => player.profile?.id === profileId);

  // if current user if mathcing players is empty
  if (matchingPlayers.length === 0) {
    return null;
  }

  return matchingPlayers[0]?.profile;
};

export const positions = [
  'center',
  'leftwing',
  'rightwing',
  'defense',
  'goalie',
];
