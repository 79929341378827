import { createTheme } from '@mui/material/styles';

const heliosTheme = createTheme({
  typography: {
    fontFamily: [
      // 'Poppins',
      'Roboto Condensed',
      'sans-serif',
    ].join(','),
    h1: {
      fontWeight: 700,
      fontSize: '3rem',
    },
    h2: {
      fontWeight: 700,
      fontSize: '2rem',
    },
    h3: {
      fontWeight: 700,
      fontSize: '1.5rem',
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.2rem',
    },
    h5: {
      fontWeight: 700,
      fontSize: '1rem',
    },
    h6: {
      fontWeight: 700,
      fontSize: '.8rem',
    },
    button: {
      fontWeight: 700,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#F26014',
    },
    info: {
      main: '#fff',
    },
  },
});

export {
  heliosTheme,
};
