import React, { ReactNode } from 'react';
import {xAxisWithTicksStyle, yAxisStyle} from '../../services/graphService';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryArea,
} from 'victory';
import { Box, useTheme } from '@mui/material';
import { SizeMe } from 'react-sizeme';

interface Props {
  data: any[];
  metric: string;
  avg?: any;
  xAxisOverride?: ReactNode;
  yAxisOverride?: ReactNode;
  maxTime: number;
}

const getXAxisTickValues = (maxTime: number) => {
  const values = [];

  // if session is longer than 1h then show 20m increments
  if (maxTime > 60 * 60) {
    for (let i = 0; i < Math.ceil(maxTime / (60 * 20)); i++) {
      values.push(60 * 20 * i);
    }
  }
  // if session is longer than 15m then show 5m increments
  else if (maxTime > 60 * 15) {
    for (let i = 0; i < Math.ceil(maxTime / (60 * 5)); i++) {
      values.push(60 * 5 * i);
    }
  }
  // if session is shorter than 15m then show 1m increments
  else {
    for (let i = 0; i < Math.ceil(maxTime / 60); i++) {
      values.push(60 * i);
    }
  }

  return values;
};

const PlayerSessionLineChart: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Box>
      <SizeMe>
        {({ size }) => (
          <VictoryChart
            width={size.width ?? 0}
            domainPadding={{x: 0, y: 30}}
            padding={{left: 0, top: 30, bottom: 30, right: 0}}
            containerComponent={
              <VictoryVoronoiContainer
                voronoiDimension="x"
                labels={({datum}) => `${datum.y.toFixed(1)}${props.metric}`}
                labelComponent={
                  <VictoryTooltip
                    style={{
                      fontSize: 20,
                      fontFamily: theme.typography.fontFamily,
                    }}
                    cornerRadius={5}
                    pointerLength={20}
                    flyoutStyle={{stroke: 'white', fill: 'white'}}
                  />
                }
                voronoiBlacklist={['area1']}
              />
            }>
            <VictoryArea
              name="area1"
              style={{data: {fill: 'rgba(242,96,20,0.15)'}}}
              data={props.data}
            />
            <VictoryLine
              data={props.data}
              style={{
                data: {stroke: theme.palette.primary.main, strokeWidth: 1},
                labels: {fill: 'white', fontSize: 20},
              }}
              interpolation="monotoneX"
              // GOOD OPTIONS -  "catmullRom", "linear", "monotoneX"
              // LESS-GOOD OPTIONS - "cardinal", "basis", "bundle", "step", "stepAfter", "stepBefore", "monotoneY", "natural"
            />
            {props.xAxisOverride ? (
              props.xAxisOverride
            ) : (
              <VictoryAxis
                tickFormat={(tick) => (tick > 0 ? `${tick / 60}m` : '')}
                tickValues={getXAxisTickValues(props.maxTime)}
                orientation="bottom"
                style={xAxisWithTicksStyle}
              />
            )}
            {props.yAxisOverride ? (
              props.yAxisOverride
            ) : (
              <VictoryAxis
                dependentAxis
                tickFormat={(tick) => `${tick}${props.metric}`}
                orientation="right"
                style={yAxisStyle}
                offsetX={size.width ?? 0}
              />
            )}
          </VictoryChart>
        )}
      </SizeMe>
    </Box>
  );
};

export default PlayerSessionLineChart;
