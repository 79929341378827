import {
  Alert,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import ReactGA from 'react-ga4';
import {createCustomerPortalSession} from '../../api/membershipAPI';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {
  getProfileDetailAsync,
  getProfileMembershipAsync,
} from '../../store/profileSlice';
import {getPlayerSessionsAsync} from '../../store/playerSessionSlice';
import {DateTime} from 'luxon';
import FullScreenLoadingSpinner from '../shared/FullScreenLoading';
import {Helmet} from 'react-helmet';
import {useParams} from 'react-router-dom';
import {getChildProfile} from '../../services/familyService';
import PageTitle from '../shared/PageTitle';
import PageSubtitle from '../shared/PageSubtitle';
import {useTranslation} from 'react-i18next';
import ProfileIdentity from '../shared/ProfileIdentity';
import {getDevicesAsync} from '../../store/deviceSlice';
import BackButton from '../shared/BackButton';
import {Profile} from '../../interfaces';

interface Props {}

const MembershipPro: React.FC<Props> = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const currentState = useAppSelector(state => ({
    profile: state.profile,
    device: state.device,
    playerSession: state.playerSession,
    family: state.family,
  }));

  const {profileId} = useParams();
  const profileIdInt = parseInt(profileId || '');

  const [disabledButtons, setDisableButtons] = useState<boolean>(false);

  useEffect(() => {
    // get the child profile if necessary
    if (profileIdInt) {
      dispatch(getProfileDetailAsync({profileId: profileIdInt}));
    }
    dispatch(getDevicesAsync({profileId: profileIdInt || undefined}));
    dispatch(getPlayerSessionsAsync({profileId: profileIdInt || undefined}));
    dispatch(getProfileMembershipAsync({profileId: profileIdInt || undefined}));
  }, [dispatch, profileIdInt]);

  const profile = profileIdInt
    ? currentState?.profile?.profileDetail
    : currentState?.profile?.profile;

  const activeShopifySubscription = profile?.shopifySubscriptionId;
  const activeStripeSubscription =
    currentState.profile?.membership?.current &&
    currentState.profile?.membership?.current.name !== 'rec' &&
    !currentState.profile?.membership?.current.isTrial;
  const device = currentState?.device.devices?.[0];

  const isProTrialEligible =
    !activeShopifySubscription &&
    !activeStripeSubscription &&
    (device?.is12MonthMembership || device?.hasStartedShopifySubscription);

  useEffect(() => {
    if (isProTrialEligible && profile) {
      ReactGA.event({
        category: 'Membership',
        action: 'Individual Pro Signup Checkout Redirect',
      });
      setTimeout(() => {
        createCustomerPortalSession({
          profileId: profile?.id,
          membershipLevel: 'pro_month',
        }).then((res: any) => {
          if (res?.data?.url) {
            window.location = res?.data?.url;
          }
        });
      }, 100);
    }
  }, [isProTrialEligible, profile, profile?.id]);

  const title = t('navigation.pageTitle', {pageName: t('pages.membershipPro')});

  const childProfile = getChildProfile({
    family: currentState?.family?.families?.[0],
    profileId: profileIdInt,
  });

  if (
    currentState.profile?.status === 'loading' ||
    !currentState?.profile?.profile ||
    (profileIdInt && !currentState?.profile?.profileDetail)
  ) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <FullScreenLoadingSpinner />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <PageTitle>Membership Pro Signup</PageTitle>
        {profileId && (
          <BackButton navigationUrl="/membership/pro">Back</BackButton>
        )}
        {childProfile && (
          <ProfileIdentity
            firstName={childProfile?.firstName}
            lastName={childProfile?.lastName}
            position={childProfile?.position}
            profilePictureUrl={childProfile?.profilePictureUrl}
          />
        )}
        {/* user has membership level */}
        {profile.membershipLevel ? (
          <>
            <PageSubtitle>
              Current Membership Level:{' '}
              {profile.membershipLevel
                .toUpperCase()
                .replace('_MONTH', '')
                .replace('_YEAR', '')
                .replace('_24MONTH', '')}
            </PageSubtitle>
            {/* if there is no active subscription and no paired device show an error */}
            {!device &&
              !activeShopifySubscription &&
              !activeStripeSubscription && (
                <Alert severity="warning" variant="outlined" sx={{mb: 2}}>
                  Please complete your HELIOS App profile and pair your CORE to
                  continue.
                </Alert>
              )}
            {/* user has at least 1 session and a trial period in the future */}
            {!activeShopifySubscription &&
            DateTime.fromISO(profile.membershipTrialEndDate) >
              DateTime.now() ? (
              <Typography
                variant="body1"
                sx={{
                  mb: 2,
                }}>{`Your intro membership will end on ${DateTime.fromISO(
                profile.membershipTrialEndDate,
              ).toFormat('MM/dd/yyyy')}`}</Typography>
            ) : null}
            {!activeShopifySubscription &&
            activeStripeSubscription &&
            currentState?.profile?.membership?.current ? (
              <Typography
                variant="body1"
                sx={{
                  mb: 2,
                }}>{`Your current plan is ${currentState?.profile?.membership?.current.name
                .toUpperCase()
                .replace('_MONTH', '/monthly')
                .replace('_YEAR', '/yearly')
                .replace('_24MONTH', '/24 months')}`}</Typography>
            ) : null}
            {!activeShopifySubscription &&
            activeStripeSubscription &&
            currentState?.profile?.membership?.next ? (
              <Typography variant="body1" sx={{mb: 2}}>{`On ${DateTime.fromISO(
                currentState?.profile?.membership?.next.startDate,
              ).toFormat(
                'MM/dd/yyyy',
              )} you will change to the ${currentState?.profile?.membership?.next.name
                .toUpperCase()
                .replace('_MONTH', '/monthly')
                .replace('_YEAR', '/yearly')
                .replace('24MONTH', '/24 months')} plan`}</Typography>
            ) : null}
          </>
        ) : null}
        {isProTrialEligible && (
          <Button
            variant="contained"
            sx={{mb: 1, mr: 1}}
            disabled={disabledButtons}
            onClick={async () => {
              setDisableButtons(true);
              ReactGA.event({
                category: 'Membership',
                action: 'Individual Pro Signup Checkout Navigate',
              });
              setTimeout(async () => {
                const res = await createCustomerPortalSession({
                  profileId: profile.id,
                  membershipLevel: 'pro_month',
                });
                window.location = res.data.url;
              }, 100);
            }}>
            Sign Up
          </Button>
        )}
        {!isProTrialEligible && device && (
          <Alert severity="warning" variant="outlined" sx={{mb: 2}}>
            This account is not eligible for a promotional PRO membership.
          </Alert>
        )}
        {profile?.role === 'parent' && (
          <>
            {[...(currentState?.family?.families?.[0]?.childProfiles ?? [])]
              .length > 0 ? (
              <TableContainer sx={{mb: 1}} component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="center">Membership Level</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[
                      ...(currentState?.family?.families?.[0]?.childProfiles ??
                        []),
                    ].map((child: Profile) => (
                      <TableRow key={child.id}>
                        <TableCell>{`${child.firstName} ${child.lastName}`}</TableCell>
                        <TableCell align="center">
                          {(child.membershipLevel ?? '').toUpperCase()}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            disabled={disabledButtons}
                            onClick={() => {
                              window.location.pathname = `/family/children/${child.id}/membership/pro`;
                            }}>
                            Sign Up For Pro
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body1">
                No Eligible Family Members
              </Typography>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default MembershipPro;
