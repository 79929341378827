import {Alert} from '@mui/material';
import React, {useEffect, useState} from 'react';
import SelectVideoFiles from './SelectVideoFiles';
import VideoTypeHeader from './VideoTypeHeader';
import {VideoTypes} from '../../../enums';
import {useTranslation} from 'react-i18next';
import {scorecamRegex} from '../../../services/videoService';
import AddVideoWrapper from './AddVideoWrapper';

interface Props {
  setShowModal: (showModal: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  createSegment: (params: {
    fileName?: string;
    parts?: number;
    veoUrl?: string;
  }) => object;
  createSegmentComplete: (params: {
    uploadId: string;
    key: string;
    parts: object[];
  }) => void;
  processVideo: () => void;
  refresh: () => void;
}

const AddScoreCamVideo: React.FC<Props> = ({
  setShowModal,
  loading,
  setLoading,
  createSegment,
  createSegmentComplete,
  processVideo,
  refresh,
}) => {
  const {t} = useTranslation();
  const [files, setFiles] = useState<FileList | null>(null);
  const [isValidFilenames, setIsValidFilenames] = useState<boolean | null>(
    null,
  );

  useEffect(() => {
    if (files) {
      const filenames = [...files].map(file => file.name);
      const validFilenames = filenames.filter(filename =>
        scorecamRegex.exec(filename),
      );
      setIsValidFilenames(validFilenames.length === filenames.length);
    } else {
      setIsValidFilenames(null);
    }
  }, [files]);

  return (
    <AddVideoWrapper type={VideoTypes.scorecam}>
      <VideoTypeHeader type={VideoTypes.scorecam} />
      {files && !isValidFilenames && (
        <Alert severity="warning" variant="outlined" sx={{mb: 1}}>
          {t('video.videoTypes.scorecam.invalidFilenameError')}
        </Alert>
      )}
      <SelectVideoFiles
        setShowModal={setShowModal}
        loading={loading}
        setLoading={setLoading}
        files={files}
        setFiles={setFiles}
        createSegment={createSegment}
        createSegmentComplete={createSegmentComplete}
        processVideo={processVideo}
        refresh={refresh}
      />
    </AddVideoWrapper>
  );
};

export default AddScoreCamVideo;
