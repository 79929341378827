import {Box, styled, Typography} from '@mui/material';
import React from 'react';
import {textColorGray} from '../../styles';

const StatWrapper = styled(Box)((props: {size?: 'default' | 'small'}) => ({
  display: 'flex',
  flexDirection: 'column',
  flexBasis: 0,
  flexGrow: 1,
  alignItems: 'center',
  marginTop: props.size === 'small' ? '1px' : '5px',
  marginBottom: props.size === 'small' ? '1px' : '5px',
}));

const StatValueWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
}));

const StatValue = styled(Typography)(() => ({
  fontFamily: 'Orbitron',
}));

const StatValueUnit = styled(Typography)(() => ({
  marginLeft: '2px',
  color: textColorGray,
}));

const StatCaption = styled(Typography)(
  (props: {whiteLabel?: boolean; size?: 'default' | 'small'}) => ({
    color: props.whiteLabel ? '#ffffff' : textColorGray,
    textAlign: 'center',
    marginTop: props.size === 'small' ? '1px' : '5px',
  }),
);

interface Props {
  label?: string;
  unit?: string;
  value: string | number | null;
  whiteLabel?: boolean;
  size?: 'default' | 'small';
  shrinkText?: boolean;
}

const SessionTileStat: React.FC<Props> = ({
  label,
  unit,
  value,
  whiteLabel,
  size,
  shrinkText,
}) => {
  return (
    <StatWrapper size={size}>
      <StatValueWrapper>
        <StatValue variant={shrinkText ? 'body2' : 'h4'}>{value}</StatValue>
        {unit ? <StatValueUnit variant="body2">{unit}</StatValueUnit> : null}
      </StatValueWrapper>
      {label ? (
        <StatCaption whiteLabel={whiteLabel} size={size} variant="body2">
          {label}
        </StatCaption>
      ) : null}
    </StatWrapper>
  );
};

export default SessionTileStat;
