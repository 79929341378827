const compare = (a: any, b: any) => {
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
};

const isNullOrUndefined = (value: unknown) =>
  value === null || value === undefined;

export {compare, isNullOrUndefined};
