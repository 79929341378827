import React from 'react';
import {
  Box,
  Button,
  TableCell,
  styled,
  TableRow,
  Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import {getTime} from '../../services/dateService';
import {getIntensityText} from '../../services/playerSessionService';
import styles from '../../styles';
import ProfileIdentity from './ProfileIdentity';
import SessionInfo from './SessionInfo';
import SessionTileStat from './SessionTileStat';

const TableCellWrapper = styled(TableCell)(({theme}) => ({
  [theme.breakpoints.down('md')]: {
    display: 'block',
    border: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'table-cell',
  },
}));

interface Props {
  navigateUrl: string;
  type: string;
  hasPlayerData: boolean;
  profileInfo?: {
    firstName: string;
    lastName: string;
    position: string;
    profilePictureUrl?: string;
  };
  startTime?: Date;
  endTime?: Date;
  activeTime?: number;
  strideCount?: number;
  hustleScore?: number;
  points?: number;
}

const PlayerSessionListItem: React.FC<Props> = ({
  navigateUrl,
  type,
  hasPlayerData,
  profileInfo,
  startTime,
  endTime,
  activeTime,
  strideCount,
  hustleScore,
  points,
}) => {
  const {t} = useTranslation();
  return (
    <TableRow
      sx={{borderBottom: {xs: '1px solid rgb(81, 81, 81)', md: 'none'}}}>
      <TableCellWrapper>
        {profileInfo ? (
          <ProfileIdentity
            firstName={profileInfo.firstName}
            lastName={profileInfo.lastName}
            position={profileInfo.position}
            profilePictureUrl={profileInfo.profilePictureUrl}
          />
        ) : (
          <SessionInfo
            sxTitleAndLengthBox={{
              display: {xs: 'flex', md: 'block'},
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            type={type}
            startTime={startTime}
            endTime={endTime}
          />
        )}
      </TableCellWrapper>
      <TableCellWrapper>
        {hasPlayerData ? (
          <Box sx={{...styles.flexDirectionRow}}>
            <SessionTileStat
              label="Active Time"
              value={getTime(activeTime) ?? '--'}
            />
            <SessionTileStat label="Strides" value={strideCount ?? '--'} />
            <SessionTileStat
              label="Hustle"
              value={
                hustleScore ? getIntensityText(hustleScore).toUpperCase() : '--'
              }
            />
            <SessionTileStat label="Points" value={points ?? '--'} />
          </Box>
        ) : (
          <Typography variant="h4" sx={{...styles.textCenter}}>
            Pending Upload
          </Typography>
        )}
      </TableCellWrapper>
      <TableCellWrapper align="right">
        {hasPlayerData && (
          <Button component={NavLink} to={navigateUrl}>
            {t('general.details')}
          </Button>
        )}
      </TableCellWrapper>
    </TableRow>
  );
};

export default PlayerSessionListItem;
