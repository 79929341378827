import React from 'react';
import {
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import {CurrentMembershipLevels, MembershipLevels} from '../../enums';
import {useTranslation} from 'react-i18next';

// TODO FIXME these strings should be localized
const permissions: {
  name: string;
  rec: boolean;
  elite: boolean;
  pro: boolean;
}[] = [
  {
    name: 'Track Practice and Game Sessions',
    rec: true,
    elite: true,
    pro: true,
  },
  {
    name: 'Weekly Progress Tracking',
    rec: true,
    elite: true,
    pro: true,
  },
  {
    name: 'Hustle Score',
    rec: true,
    elite: true,
    pro: true,
  },
  {
    name: 'Social Leaderboards',
    rec: true,
    elite: true,
    pro: true,
  },
  {
    name: 'Weekly, Monthly, All-Time Progress and Trend Tracking',
    rec: false,
    elite: true,
    pro: true,
  },
  {
    name: 'Rankings and Metrics By Birth Year',
    rec: false,
    elite: true,
    pro: true,
  },
  {
    name: 'Shift Analysis',
    rec: false,
    elite: true,
    pro: true,
  },
  {
    name: 'Heart Rate Integration',
    rec: false,
    elite: true,
    pro: true,
  },
  {
    name: 'Access to Training Videos',
    rec: false,
    elite: true,
    pro: true,
  },
  {
    name: 'Instant Video Highlights',
    rec: false,
    elite: false,
    pro: true,
  },
];

const PlanCard = styled(Card)(() => ({
  backgroundColor: '#fff',
}));

const PlanTitle = styled(Box)(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  textAlign: 'center',
  padding: 10,
}));

const PlanPricing = styled(Box)(() => ({
  padding: 10,
  color: '#000',
}));

const PlanButtonContainer = styled(Box)(() => ({
  minHeight: 145,
  padding: 10,
}));

interface Props {
  isActive: boolean;
  membershipLevel: CurrentMembershipLevels;
  showButtons?: boolean;
  membershipButtons?: {
    membershipLevel: MembershipLevels;
    onClick: () => void;
  }[];
}

const getPrice = (membershipLevel: CurrentMembershipLevels) => {
  // TODO FIXME these strings should be localized
  switch (membershipLevel) {
    case CurrentMembershipLevels.rec:
      return 'FREE';
    case CurrentMembershipLevels.elite:
      return '$19/month or $199/year';
    case CurrentMembershipLevels.pro:
      return '$30/month, $300/year, or $480/24 months';
    default:
      return '';
  }
};

const getButtonPrice = (membershipLevel: MembershipLevels) => {
  // TODO FIXME these strings should be localized
  switch (membershipLevel) {
    case MembershipLevels.rec:
      return 'Rec';
    case MembershipLevels.eliteMonth:
      return 'Elite Monthly ($19)';
    case MembershipLevels.eliteYear:
      return 'Elite Yearly ($199)';
    case MembershipLevels.proMonth:
      return 'Pro Monthly ($30)';
    case MembershipLevels.proYear:
      return 'Pro Yearly ($300)';
    case MembershipLevels.pro24Months:
      return 'Pro 24 Month ($480)';
    default:
      return '';
  }
};

const MembershipTier: React.FC<Props> = ({
  isActive,
  membershipLevel,
  showButtons,
  membershipButtons,
}) => {
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        border: isActive ? 'solid 4px #fff' : null,
        borderRadius: 2,
        padding: isActive ? '4px' : '8px',
      }}>
      <PlanCard>
        <PlanTitle>
          <Typography variant="h3">{`${membershipLevel.toUpperCase()}${membershipLevel === CurrentMembershipLevels.elite ? ' (LEGACY)' : ''}`}</Typography>
        </PlanTitle>
        <PlanPricing>
          <Typography variant="h4">{getPrice(membershipLevel)}</Typography>
        </PlanPricing>
        {showButtons && (
          <PlanButtonContainer>
            {(membershipButtons ?? []).length > 0 && (
              <>
                <Typography variant="h5" color="#000" mb={1}>
                  {t('membership.selectPlan')}
                </Typography>
                <Box>
                  <Grid container spacing={1}>
                    {(membershipButtons ?? []).map(membershipButton => (
                      <Grid
                        key={membershipButton.membershipLevel}
                        item
                        xs={12 / (membershipButtons ?? []).length}>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{mb: 1, width: '100%', height: '100%'}}
                          onClick={membershipButton.onClick}>
                          {getButtonPrice(membershipButton.membershipLevel)}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </>
            )}
          </PlanButtonContainer>
        )}
        <Table size="small">
          <TableBody>
            {permissions.map(permission => (
              <TableRow sx={{height: 45}} key={permission.name}>
                <TableCell
                  sx={{
                    color: permission[membershipLevel]
                      ? '#000'
                      : 'rgba(0,0,0,50%)',
                    borderBottom: 'none',
                  }}>
                  {permission.name}
                </TableCell>
                <TableCell
                  sx={{
                    color: '#000',
                    textAlign: 'center',
                    width: '20%',
                    borderBottom: 'none',
                  }}>
                  {permission[membershipLevel] ? <CheckIcon /> : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PlanCard>
    </Box>
  );
};

export default MembershipTier;
