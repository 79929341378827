import React from 'react';
import {IconButton} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

interface Props {
  href: string;
}

const Download: React.FC<Props> = ({href}) => (
  <IconButton href={href} download>
    <DownloadIcon />
  </IconButton>
);

export default Download;
