import React from 'react';
import {IconButton} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

interface Props {
  isFullscreen: boolean;
  toggleFullscreen: () => void;
}

const Fullscreen: React.FC<Props> = ({isFullscreen, toggleFullscreen}) => (
  <IconButton onClick={toggleFullscreen}>
    {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
  </IconButton>
);

export default Fullscreen;
