import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {createCustomerPortalSession} from '../../api/membershipAPI';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {
  getCurrentProfileAsync,
  getProfileDetailAsync,
  getProfileMembershipAsync,
  updateProfileMembershipAsync,
} from '../../store/profileSlice';
import {getPlayerSessionsAsync} from '../../store/playerSessionSlice';
import {DateTime} from 'luxon';
import styles from '../../styles';
import FullScreenLoadingSpinner from '../shared/FullScreenLoading';
import {CurrentMembershipLevels, MembershipLevels} from '../../enums';
import {Helmet} from 'react-helmet';
import {useParams} from 'react-router-dom';
import {getChildProfile} from '../../services/familyService';
import PageTitle from '../shared/PageTitle';
import PageSubtitle from '../shared/PageSubtitle';
import {useTranslation} from 'react-i18next';
import ProfileIdentity from '../shared/ProfileIdentity';
import FamilyMemberList from '../shared/FamilyMemberList';
import MembershipTier from './MembershipTier';
import {claimShopifySubscription} from '../../api/shopifySubscriptionAPI';
import {getDevicesAsync} from '../../store/deviceSlice';

interface Props {}

const Membership: React.FC<Props> = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const currentState = useAppSelector(state => ({
    profile: state.profile,
    device: state.device,
    playerSession: state.playerSession,
    family: state.family,
  }));

  const {profileId} = useParams();
  const profileIdInt = parseInt(profileId || '');

  const [showShopifySubscriptionModal, setShowShopifySubscriptionModal] =
    useState<boolean>(false);
  const [shopifySubscriptionCode, setShopifySubscriptionCode] =
    useState<string>('');
  const [shopifySubscriptionApiLoading, setShopifySubscriptionApiLoading] =
    useState<boolean>(false);
  const [shopifySubscriptionError, setShopifySubscriptionError] =
    useState<string>('');
  const [showMembershipLevelModal, setShowMembershipLevelModal] =
    useState<boolean>(false);
  const [membershipLevel, setMembershipLevel] = useState<string>('');
  const [updateMembershipLevelError, setUpdateMembershipLevelError] =
    useState<string>('');

  useEffect(() => {
    // get the child profile if necessary
    if (profileIdInt) {
      dispatch(getProfileDetailAsync({profileId: profileIdInt}));
    }
    dispatch(getDevicesAsync({profileId: profileIdInt || undefined}));
    dispatch(getPlayerSessionsAsync({profileId: profileIdInt || undefined}));
    if (profileIdInt || currentState?.profile?.profile?.stripeCustomerId) {
      dispatch(
        getProfileMembershipAsync({profileId: profileIdInt || undefined}),
      );
    }
  }, [
    currentState?.profile?.profile?.stripeCustomerId,
    dispatch,
    profileIdInt,
  ]);

  const profile = profileIdInt
    ? currentState?.profile?.profileDetail
    : currentState?.profile?.profile;

  const title = t('navigation.pageTitle', {pageName: t('pages.membership')});

  const childProfile = getChildProfile({
    family: currentState?.family?.families?.[0],
    profileId: profileIdInt,
  });

  if (
    currentState.profile?.status === 'loading' ||
    !currentState?.profile?.profile ||
    (profileIdInt && !currentState?.profile?.profileDetail)
  ) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <FullScreenLoadingSpinner />
      </>
    );
  }

  const activeShopifySubscription = profile.shopifySubscriptionId;
  const activeStripeSubscription =
    currentState.profile?.membership?.current &&
    currentState.profile?.membership?.current.name !== 'rec' &&
    !currentState.profile?.membership?.current.isTrial;
  const device = currentState?.device.devices?.[0];
  const showStripeSubscriptionManagement =
    !activeShopifySubscription &&
    (device?.is12MonthMembership ||
      device?.hasStartedShopifySubscription ||
      activeStripeSubscription);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <PageTitle>Membership</PageTitle>
        {childProfile && (
          <ProfileIdentity
            firstName={childProfile?.firstName}
            lastName={childProfile?.lastName}
            position={childProfile?.position}
            profilePictureUrl={childProfile?.profilePictureUrl}
          />
        )}
        {/* user has membership level */}
        {profile?.membershipLevel && (
          <>
            <PageSubtitle>
              Current Membership Level:{' '}
              {profile?.membershipLevel
                .toUpperCase()
                .replace('_MONTH', '')
                .replace('_YEAR', '')
                .replace('_24MONTH', '')}
            </PageSubtitle>
            {/* user has at least 1 session and a trial period in the future */}
            {!activeShopifySubscription &&
            DateTime.fromISO(profile.membershipTrialEndDate) >
              DateTime.now() ? (
              <Typography
                variant="body1"
                sx={{
                  mb: 2,
                }}>{`Your intro membership will end on ${DateTime.fromISO(
                profile.membershipTrialEndDate,
              ).toFormat('MM/dd/yyyy')}`}</Typography>
            ) : null}
            {!activeShopifySubscription &&
            activeStripeSubscription &&
            currentState?.profile?.membership?.current ? (
              <Typography
                variant="body1"
                sx={{
                  mb: 2,
                }}>{`Your current plan is ${currentState?.profile?.membership?.current.name
                .toUpperCase()
                .replace('_MONTH', '/monthly')
                .replace('_YEAR', '/annually')
                .replace('_24MONTH', '/24 months')}`}</Typography>
            ) : null}
            {!activeShopifySubscription &&
            activeStripeSubscription &&
            currentState?.profile?.membership?.next ? (
              <Typography variant="body1" sx={{mb: 2}}>{`On ${DateTime.fromISO(
                currentState?.profile?.membership?.next.startDate,
              ).toFormat(
                'MM/dd/yyyy',
              )} you will change to the ${currentState?.profile?.membership?.next.name
                .toUpperCase()
                .replace('_MONTH', '/monthly')
                .replace('_YEAR', '/annually')
                .replace('_24MONTH', '/24 months')} plan`}</Typography>
            ) : null}
          </>
        )}
        {currentState.profile?.profile?.role === 'parent' && !profileId ? (
          <Box sx={{mb: 3}}>
            <FamilyMemberList
              familyMembers={[
                ...(currentState?.family?.families?.[0]?.childProfiles ?? []),
              ]}
              showMembershipLinkReload={true}
            />
          </Box>
        ) : (
          <>
            {/* if there is no active subscription and no paired device show an error */}
            {!device &&
              !activeShopifySubscription &&
              !activeStripeSubscription && (
                <Alert severity="warning" variant="outlined" sx={{mb: 2}}>
                  Please complete your HELIOS App profile and pair your CORE to
                  continue.
                </Alert>
              )}
            {/* show elite plan deprecation warning */}
            {profile?.membershipLevel === CurrentMembershipLevels.elite && (
              <Alert severity="warning" variant="outlined" sx={{mb: 2}}>
                Elite plans have been deprecated. You can continue to stay on
                this plan, however if you switch to PRO or REC you will not be
                able to switch back.
              </Alert>
            )}
            {/* if there is a device set for shopify and no active subscription */}
            {device &&
              !device.is12MonthMembership &&
              !device.hasStartedShopifySubscription &&
              !activeShopifySubscription && (
                <>
                  <Alert severity="info" variant="outlined" sx={{mb: 2}}>
                    {t('membership.shopifySubscriptionInstructions')}
                  </Alert>
                  <Button
                    variant="contained"
                    sx={{mb: 2}}
                    onClick={async () => {
                      setShowShopifySubscriptionModal(true);
                    }}>
                    {t('membership.claimMembership')}
                  </Button>
                </>
              )}
            {/* membership tier columns with buttons for managing stripe subscriptions */}
            {(device ||
              activeShopifySubscription ||
              activeStripeSubscription) && (
              <Grid container spacing={3} sx={{mb: 3}}>
                <Grid
                  item
                  xs={12}
                  md={
                    profile?.membershipLevel === CurrentMembershipLevels.elite
                      ? 4
                      : 6
                  }>
                  <MembershipTier
                    isActive={
                      profile?.membershipLevel === CurrentMembershipLevels.rec
                    }
                    membershipLevel={CurrentMembershipLevels.rec}
                    showButtons={showStripeSubscriptionManagement}
                    membershipButtons={[
                      {
                        membershipLevel: MembershipLevels.rec,
                        onClick: () => {
                          setMembershipLevel(MembershipLevels.rec);
                          setShowMembershipLevelModal(true);
                        },
                      },
                    ]}
                  />
                </Grid>
                {profile?.membershipLevel === CurrentMembershipLevels.elite && (
                  <Grid
                    item
                    xs={12}
                    md={
                      profile?.membershipLevel === CurrentMembershipLevels.elite
                        ? 4
                        : 6
                    }>
                    <MembershipTier
                      isActive={
                        profile?.membershipLevel ===
                        CurrentMembershipLevels.elite
                      }
                      membershipLevel={CurrentMembershipLevels.elite}
                      showButtons={showStripeSubscriptionManagement}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  md={
                    profile?.membershipLevel === CurrentMembershipLevels.elite
                      ? 4
                      : 6
                  }>
                  <MembershipTier
                    isActive={
                      profile?.membershipLevel === CurrentMembershipLevels.pro
                    }
                    membershipLevel={CurrentMembershipLevels.pro}
                    showButtons={showStripeSubscriptionManagement}
                    membershipButtons={[
                      {
                        membershipLevel: MembershipLevels.proMonth,
                        onClick: () => {
                          setMembershipLevel(MembershipLevels.proMonth);
                          setShowMembershipLevelModal(true);
                        },
                      },
                      {
                        membershipLevel: MembershipLevels.proYear,
                        onClick: () => {
                          setMembershipLevel(MembershipLevels.proYear);
                          setShowMembershipLevelModal(true);
                        },
                      },
                      {
                        membershipLevel: MembershipLevels.pro24Months,
                        onClick: () => {
                          setMembershipLevel(MembershipLevels.pro24Months);
                          setShowMembershipLevelModal(true);
                        },
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            )}
          </>
        )}
        {/* stripe subscription management */}
        {showStripeSubscriptionManagement && (
          <Button
            variant="contained"
            sx={{mb: 1, mr: 1}}
            onClick={async () => {
              const res = await createCustomerPortalSession({
                profileId: profile.id,
              });
              window.location = res.data.url;
            }}>
            Update Payment Information
          </Button>
        )}
        {/* shopify subscription management */}
        {activeShopifySubscription && (
          <Button
            variant="contained"
            sx={{mb: 1, mr: 1}}
            onClick={async () => {
              window.location.href = `${process.env.REACT_APP_MAIN_WEBSITE_BASE_URL}/tools/recurring/login`;
            }}>
            {t('membership.updateMembershipInformation')}
          </Button>
        )}
        {/* stripe subscription membership change modal */}
        <Modal
          open={showMembershipLevelModal}
          onClose={() => {
            setShowMembershipLevelModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={styles.modal}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant="h4" sx={{mb: 1}}>
                Update Membership Level
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mb: 1,
                }}>{`Change membership level to: ${membershipLevel.replaceAll('_', ' ').toUpperCase().replace('24MONTH', '24 MONTH')}`}</Typography>
              {updateMembershipLevelError ? (
                <Alert severity="error" variant="outlined" sx={{mb: 1}}>
                  {updateMembershipLevelError}
                </Alert>
              ) : null}
              <Button
                variant="contained"
                sx={{mb: 1}}
                onClick={async () => {
                  const res = await dispatch(
                    updateProfileMembershipAsync({
                      profileId: profileIdInt || undefined,
                      membershipLevel,
                    }),
                  );
                  if (res?.payload?.success && res?.payload?.url) {
                    window.location = res.payload.url;
                  }
                  if (res?.payload?.data?.error) {
                    setUpdateMembershipLevelError(res?.payload?.data?.error);
                  }
                }}>
                Confirm
              </Button>
              {(childProfile ?? profile).stripeCustomerId ? (
                <Button
                  variant="contained"
                  color="info"
                  sx={{mb: 1}}
                  onClick={async () => {
                    const res = await createCustomerPortalSession({
                      profileId: (childProfile ?? profile).id,
                    });
                    window.location = res.data.url;
                  }}>
                  Update Payment Information
                </Button>
              ) : null}
              <Button
                variant="contained"
                color="error"
                sx={{mb: 1}}
                onClick={() => {
                  setShowMembershipLevelModal(false);
                }}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
        {/* shopify membership claim modal */}
        <Modal
          open={showShopifySubscriptionModal}
          onClose={() => {
            setShowShopifySubscriptionModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={styles.modal}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant="h4" sx={{mb: 2}}>
                Enter Membership Key
              </Typography>
            </Box>
            <TextField
              label="Membership Key"
              sx={{width: '100%', mb: 3}}
              onChange={e => setShopifySubscriptionCode(e.target.value)}
              value={shopifySubscriptionCode}
            />
            {shopifySubscriptionError.length > 0 && (
              <Alert severity="error" variant="outlined" sx={{mb: 2}}>
                {shopifySubscriptionError}
              </Alert>
            )}
            <Box sx={{textAlign: 'right'}}>
              <Button
                variant="contained"
                disabled={
                  shopifySubscriptionCode.length !== 36 ||
                  shopifySubscriptionApiLoading
                }
                onClick={async () => {
                  setShopifySubscriptionApiLoading(true);
                  const res = await claimShopifySubscription({
                    code: shopifySubscriptionCode,
                    profileId: profile.id,
                  });
                  if (res.data?.data?.claimShopifySubscription?.error) {
                    setShopifySubscriptionApiLoading(false);
                    setShopifySubscriptionError(
                      res.data?.data?.claimShopifySubscription?.error,
                    );
                  } else {
                    setShopifySubscriptionError('');
                    // fetch profile again
                    if (profileIdInt) {
                      dispatch(
                        getProfileDetailAsync({profileId: profileIdInt}),
                      );
                    } else {
                      dispatch(getCurrentProfileAsync());
                    }
                    setShopifySubscriptionApiLoading(false);
                    setShowShopifySubscriptionModal(false);
                  }
                }}>
                Submit
                {shopifySubscriptionApiLoading ? (
                  <CircularProgress sx={{color: '#fff', ml: 1}} size={16} />
                ) : null}
              </Button>
            </Box>
          </Box>
        </Modal>
      </Container>
    </>
  );
};

export default Membership;
