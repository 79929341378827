import { AxiosPromise } from 'axios';
import api from './api';

export function postAdminUtil(params: {
  job: string,
  data: any,
  onError?: () => void,
  onSuccess?: () => void,
}) {
  const {job, data, onError, onSuccess} = params;

  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .post(`/admin/util`, {job, data})
        .then((response: any) => {
          resolve(response);
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((e: Error) => {
          reject(e);
          if (onError) {
            onError();
          }
        });
    } catch (e) {
      reject(e);
      if (onError) {
        onError();
      }
    }
  });
}
