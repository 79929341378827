const getIntensityText = (value?: number) => {
  if (value === 1) {
    return 'Low';
  } else if (value === 2) {
    return 'Avg';
  } else if (value === 3) {
    return 'High';
  }
  return '';
};

export {
  getIntensityText,
};
