import React from 'react';
import { Box, Button, CssBaseline, ThemeProvider } from '@mui/material';
import { heliosTheme } from '../theme';
import { Helmet } from 'react-helmet';
import PageTitle from './shared/PageTitle';
import { useTranslation } from 'react-i18next';

interface Props {}

const ErrorPage: React.FC<Props> = (props) => {
  const {t} = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('navigation.pageTitle', {pageName: t('pages.error')})}</title>
      </Helmet>
      <ThemeProvider theme={heliosTheme}>
        <CssBaseline />
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}>
          <PageTitle>{t('general.somethingWentWrong')}</PageTitle>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              window.location.pathname = '/';
            }}>
            {t('general.returnHome')}
          </Button>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default ErrorPage; 
