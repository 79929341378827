import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
} from '@mui/material';
import Hls from 'hls.js';
import {DateTime} from 'luxon';
import React, {useEffect, useRef, useState} from 'react';

interface Props {
  videoUrl: string;
  sessionEvents: any[];
  setVideoStartTime: (startTime: Date) => void;
}

const VideoSessionEventAnchor: React.FC<Props> = ({
  videoUrl,
  sessionEvents,
  setVideoStartTime,
}) => {
  const [sessionEventTime, setSessionEventTime] = useState<Date | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoUrl.toLowerCase().endsWith('.m3u8')) {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(videoUrl);
        hls.attachMedia(videoRef.current!);

        return () => {
          hls.destroy(); // Cleanup HLS instance when component unmounts
        };
      } else if (
        videoRef.current?.canPlayType('application/vnd.apple.mpegurl')
      ) {
        // Safari support
        videoRef.current.src = videoUrl;
      }
    }
  }, [videoUrl]);

  const onPause = (e: any) => {
    if (sessionEventTime) {
      setVideoStartTime(
        DateTime.fromJSDate(sessionEventTime)
          .minus({seconds: e.target?.currentTime})
          .toJSDate(),
      );
    } else {
      setSessionEventTime(null);
    }
  };

  const onSessionEventChange = async (e: SelectChangeEvent<string>) => {
    const eventTime = DateTime.fromISO(e.target.value).toJSDate();
    setSessionEventTime(eventTime);
    if (
      videoRef.current?.paused &&
      videoRef.current?.currentTime > 0 &&
      eventTime
    ) {
      setVideoStartTime(
        DateTime.fromJSDate(eventTime)
          .minus({seconds: videoRef.current?.currentTime})
          .toJSDate(),
      );
    }
  };

  return (
    <Box>
      <video width="100%" controls onPause={onPause} ref={videoRef}>
        <source src={videoUrl} type="video/mp4" />
      </video>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">Time Zone</FormLabel>
        <RadioGroup
          onChange={onSessionEventChange}
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group">
          {sessionEvents.map(sessionEvent => (
            <FormControlLabel
              key={sessionEvent.id}
              value={sessionEvent.startTime}
              control={<Radio />}
              label={`${sessionEvent?.sessionEvent?.name} - ${DateTime.fromISO(sessionEvent?.startTime as string).toFormat('hh:mm:ss')}`}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default VideoSessionEventAnchor;
