export const getChildProfileName = (params: {
  family: any;
  profileId: number;
}) => {
  const {family, profileId} = params;
  const matchingFamilyMembers = (family?.childProfiles ?? [])
    .filter((profile: any) => profile.id === profileId);

  // if current user if family members is empty
  if (matchingFamilyMembers.length === 0) {
    return '';
  }

  return `${matchingFamilyMembers[0].firstName} ${matchingFamilyMembers[0].lastName}`;
};

export const getChildProfile = (params: {
  family: any;
  profileId: number;
}) => {
  const {family, profileId} = params;
  const matchingFamilyMembers = (family?.childProfiles ?? [])
    .filter((profile: any) => profile.id === profileId);

  // if current user if family members is empty
  if (matchingFamilyMembers.length === 0) {
    return null;
  }

  return matchingFamilyMembers[0];
};

