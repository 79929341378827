import React, {useState} from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  styled,
  Switch,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PlayerSessionShiftStat from './PlayerSessionShiftStat';
import {useTranslation} from 'react-i18next';

const ShiftNumber = styled(Typography)(() => ({
  width: '20px',
}));

const ShiftInfoWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const ShiftOpenWrapper = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
}));

interface Props {
  start: number;
  valid: boolean;
  number?: number;
  data: {
    label: string;
    value: string;
    unit?: string;
  }[];
  inReview?: boolean;
  onChangeValid: (valid: boolean) => void;
}

const PlayerSessionShift: React.FC<Props> = ({
  start,
  valid,
  number,
  data,
  inReview,
  onChangeValid,
}) => {
  const {t} = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <TableRow key={start}>
      <TableCell>
        <ShiftInfoWrapper>
          <ShiftNumber sx={{opacity: valid ? 1 : 0.25}} variant="h4">
            {number || null}
          </ShiftNumber>
          <Grid container spacing={2}>
            <PlayerSessionShiftStat valid={valid} {...data[0]} />
            <Grid sx={{display: {xs: 'block', md: 'none'}}} item xs={6} md={3}>
              <ShiftOpenWrapper>
                <Button
                  onClick={() => setOpen(!open)}
                  endIcon={
                    open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                  }>
                  {t('general.details')}
                </Button>
              </ShiftOpenWrapper>
            </Grid>
            <PlayerSessionShiftStat valid={valid} {...data[1]} />
            <PlayerSessionShiftStat valid={valid} {...data[2]} />
            <Grid sx={{display: {xs: 'none', md: 'block'}}} item xs={6} md={3}>
              <ShiftOpenWrapper>
                <Button
                  onClick={() => setOpen(!open)}
                  endIcon={
                    open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                  }>
                  {t('general.details')}
                </Button>
              </ShiftOpenWrapper>
            </Grid>
            {open && (
              <>
                <PlayerSessionShiftStat valid={valid} {...data[3]} />
                <PlayerSessionShiftStat valid={valid} {...data[4]} />
                <PlayerSessionShiftStat valid={valid} {...data[5]} />
                <PlayerSessionShiftStat valid={valid} {...data[6]} />
                <PlayerSessionShiftStat valid={valid} {...data[7]} />
                <PlayerSessionShiftStat valid={valid} {...data[8]} />
                <PlayerSessionShiftStat valid={valid} {...data[9]} />
                <Grid item xs={6} md={3} />
                <PlayerSessionShiftStat valid={valid} {...data[10]} />
                <PlayerSessionShiftStat valid={valid} {...data[11]} />
                <PlayerSessionShiftStat valid={valid} {...data[12]} />
                <PlayerSessionShiftStat valid={valid} {...data[13]} />
              </>
            )}
          </Grid>
          {inReview && (
            <FormControlLabel
              value="Valid"
              control={
                <Switch
                  inputProps={{'aria-label': t('general.valid') ?? ''}}
                  defaultChecked={valid}
                  onChange={e => onChangeValid(e.target.checked)}
                />
              }
              label={t('general.valid')}
              labelPlacement="top"
            />
          )}
        </ShiftInfoWrapper>
      </TableCell>
    </TableRow>
  );
};

export default PlayerSessionShift;
