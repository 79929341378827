import {AxiosPromise} from 'axios';
import api from './api';
import {DateTime} from 'luxon';

export function getReport({
  teamId,
  reportType,
  reportName,
  teamName,
}: {
  teamId: number;
  reportType: string;
  reportName: string;
  teamName: string;
}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get(`/util/reports?teamId=${teamId}&reportType=${reportType}`, {
          responseType: 'blob',
        })
        .then(response => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          const fileName = `helios_${teamName.replace(/[^a-z0-9]/gi, '_').toLowerCase()}_${reportName}_${DateTime.local().toFormat('yyyyMMddhhmmss')}.csv`;
          link.setAttribute('download', fileName); // or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}
