import React from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import PageTitle from './shared/PageTitle';

interface Props {}

const PageNotFound: React.FC<Props> = (props) => {
  const {t} = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('navigation.pageTitle', {pageName: t('pages.pageNotFound')})}</title>
      </Helmet>
      <PageTitle>{t('pages.pageNotFound')}</PageTitle>
    </>
  )
}

export default PageNotFound; 
