import { ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";
import Router from "../Router";
import { heliosTheme } from "../theme";

interface Props {
  user?: any;
}

const Main: React.FC<Props> = (props) => {
  const {user} = props;

  Sentry.setUser({email: user?.attributes?.email});

  return (
    <ThemeProvider theme={heliosTheme}>
      <Router />
    </ThemeProvider>
  )
}

export default Main; 
