import { Box, CircularProgress } from '@mui/material';
import React from 'react';

interface Props {}

const FullScreenLoadingSpinner: React.FC<Props> = (props) => (
  <Box sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }}>
    <CircularProgress />
  </Box>
);

export default FullScreenLoadingSpinner;
