import {AxiosPromise} from 'axios';
import api from './api';
import {DateTime} from 'luxon';
import {TeamSessionExportTypes} from '../enums';

const getFormatSubstring = (format: string) => {
  if (format === TeamSessionExportTypes.hudlSportscode) {
    return '_hudl_sportscode';
  } else if (format === TeamSessionExportTypes.spiideoPerform) {
    return '_spiideo_perform';
  } else if (format === TeamSessionExportTypes.catapultThunder) {
    return '_catapult_thunder';
  } else if (format === TeamSessionExportTypes.csvPlayers) {
    return '_players';
  } else if (format === TeamSessionExportTypes.csvShifts) {
    return '_shifts';
  } else {
    return '';
  }
};

export function getPlayerSessionExport({
  playerSessionId,
  playerSessionStartTime,
  format,
}: {
  playerSessionId: number;
  playerSessionStartTime: string;
  format: TeamSessionExportTypes.csvPlayers | TeamSessionExportTypes.csvShifts;
}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get('/util/export', {
          responseType: 'blob',
          params: {
            playerSessionId,
            format,
          },
        })
        .then(response => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          // create the export name
          const filename = `helios_playersession${getFormatSubstring(format)}_export_${DateTime.fromISO(playerSessionStartTime).toFormat('yyyyMMddhhmmss')}.${format.startsWith('csv') ? 'csv' : 'xml'}`;
          link.setAttribute('download', filename); // or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getTeamSessionExport({
  teamSessionId,
  teamSessionStartTime,
  format,
}: {
  teamSessionId: number;
  teamSessionStartTime: string;
  format:
    | TeamSessionExportTypes.csvPlayers
    | TeamSessionExportTypes.csvShifts
    | TeamSessionExportTypes.hudlSportscode
    | TeamSessionExportTypes.spiideoPerform
    | TeamSessionExportTypes.catapultThunder;
}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get('/util/export', {
          responseType: 'blob',
          params: {
            teamSessionId,
            // replace spiideoPerform with hudlSportscode
            format:
              format === TeamSessionExportTypes.spiideoPerform
                ? TeamSessionExportTypes.hudlSportscode
                : format,
          },
        })
        .then(response => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          // create the export name
          const filename = `helios_teamsession${getFormatSubstring(format)}_export_${DateTime.fromISO(teamSessionStartTime).toFormat('yyyyMMddhhmmss')}.${format.startsWith('csv') ? 'csv' : 'xml'}`;
          link.setAttribute('download', filename); // or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}
