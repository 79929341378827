import React, {useCallback, useEffect, useState} from 'react';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {Box, ListItemButton, ListItemText} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import {useTheme} from '@mui/material/styles';
import {Team} from '../../../interfaces';

interface Props {
  teams: Team[];
}

const TeamsSideMenu: React.FC<Props> = ({teams}) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTeamId, setSelectedTeamId] = useState<number>(0);

  const handleItemSelect = useCallback(
    (event: SelectChangeEvent) => {
      if (location?.pathname?.startsWith('/teams')) {
        const pathArray = location.pathname.split('/');
        navigate(`/teams/${event.target.value}/${pathArray[3]}`);
      }
      setSelectedTeamId(parseInt(event.target.value, 10));
    },
    [location],
  );

  useEffect(() => {
    if (location?.pathname) {
      const pathArray = location.pathname.split('/');
      if (pathArray.length > 2 && pathArray[1] === 'teams') {
        setSelectedTeamId(parseInt(pathArray[2], 10));
      }
    }
  }, [location?.pathname]);

  if (!teams) {
    return null;
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <Box sx={{mx: 2}}>
        <Select
          sx={{
            width: '100%',
            fontWeight: 700,
            fontSize: '1.2rem',
            fontStyle: 'italic',
            color: theme.palette.primary.main,
          }}
          labelId="team-select-label"
          value={selectedTeamId?.toString() ?? '0'}
          onChange={handleItemSelect}>
          <MenuItem value="0" disabled>
            {t('navigation.selectTeam')}
          </MenuItem>
          {teams?.map((team: Team) => (
            <MenuItem key={team.id} value={team.id}>
              {team.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <ListItemButton
          component={NavLink}
          disabled={selectedTeamId === 0}
          to={`/teams/${selectedTeamId}/players`}>
          <ListItemText
            primary={t('general.players')}
            primaryTypographyProps={{
              fontWeight: 700,
              fontSize: '1.2rem',
            }}
          />
        </ListItemButton>
        <ListItemButton
          component={NavLink}
          disabled={selectedTeamId === 0}
          to={`/teams/${selectedTeamId}/teamsessions`}>
          <ListItemText
            primary={t('general.teamSessions')}
            primaryTypographyProps={{
              fontWeight: 700,
              fontSize: '1.2rem',
            }}
          />
        </ListItemButton>
        <ListItemButton
          component={NavLink}
          disabled={selectedTeamId === 0}
          to={`/teams/${selectedTeamId}/reports`}>
          <ListItemText
            primary={t('general.reports')}
            primaryTypographyProps={{
              fontWeight: 700,
              fontSize: '1.2rem',
            }}
          />
        </ListItemButton>
      </Box>
    </Box>
  );
};

export default TeamsSideMenu;
