import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PlayerListItem from './PlayerListItem';

interface Props {
  players: any[];
  teamId: number;
}

const TeamPlayerList: React.FC<Props> = ({
  players,
  teamId,
}) => {
  const {t} = useTranslation();

  return (
    <TableContainer sx={{mb: 1}} component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          {[...(players ?? [])].map((player: any) => (
            <PlayerListItem
              key={player.id}
              firstName={player.profile?.firstName}
              lastName={player.profile?.lastName}
              position={player.profile?.position}
              profilePictureUrl={player.profile?.profilePictureFileKey}
              buttons={[
                {
                  text: t('pages.playerSessions'),
                  navigationUrl: `/teams/${teamId}/players/${player.profile?.id}/playersessions`,
                },
              ]}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

export default TeamPlayerList;
