import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {ReactNode} from 'react';
import {videoTypes} from '../../../services/videoService';
import {VideoTypes} from '../../../enums';

interface Props {
  type: VideoTypes;
  children: ReactNode;
}

const AddVideoWrapper: React.FC<Props> = ({type, children}) => {
  const videoType = videoTypes.filter(video => video.type === type)[0];

  return (
    <OuterWrapper>
      {videoType && videoType.image && (
        <LogoColumn
          sx={{
            display: {xs: 'none', md: 'flex'},
          }}>
          <LogoImage
            maxwidth={videoType.width}
            src={videoType.image}
            alt={videoType.name}
          />
        </LogoColumn>
      )}
      <InnerWrapper>{children}</InnerWrapper>
    </OuterWrapper>
  );
};

const OuterWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

const InnerWrapper = styled(Box)(() => ({
  flexGrow: 1,
}));

const LogoColumn = styled(Box)(() => ({
  flexDirection: 'column',
  justifyContent: 'center',
  alignSelf: 'stretch',
  padding: '20px',
  width: '150px',
}));

const LogoImage = styled('img')<{maxwidth?: string}>(({maxwidth}) => ({
  maxWidth: maxwidth ?? '100%',
  maxHeight: '100%',
}));

export default AddVideoWrapper;
